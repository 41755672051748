import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import Skeleton from '@mui/material/Skeleton';

import { Container, Stack, Typography, Box, Grid } from '@mui/material';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Tooltip from '@mui/material/Tooltip';
import { Helmet } from 'react-helmet-async';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import FlightTakeoffOutlinedIcon from '@mui/icons-material/FlightTakeoffOutlined';
import AirportShuttleOutlinedIcon from '@mui/icons-material/AirportShuttleOutlined';
import HotelOutlinedIcon from '@mui/icons-material/HotelOutlined';
import HikingOutlinedIcon from '@mui/icons-material/HikingOutlined';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import TravelRequestDialog from './../../components/dialog/travel_request';
import HotelsShoppingKidsTabs from './../../components/tabsFacility/tabsFacility';
import { GlobalTheme } from './../../theme';
import DynamicMantleTourDetails from './../../components/mantles/DynamicMantleTourDetails';
import axios from 'axios';
import GoogleAnalytics from '../../components/gAnalytics/gAnalytics';


const BASE_MEDIA_URL = 'https://aaryanholidays.in/express/uploads/';

const iconMapping = {
    'Flights': FlightTakeoffOutlinedIcon,
    'Hotels': HotelOutlinedIcon,
    'Transfers': AirportShuttleOutlinedIcon,
    'Activities': HikingOutlinedIcon,
    // Add more mappings here if needed
};

const parseIcons = (text) => {
    const items = text.split(', ');
    const icons = items.map(item => {
        const [count, type] = item.split(' ');
        return { count: parseInt(count, 10), type };
    });
    return icons;
};

// Start of Itinerary tabs
function TabItinerary(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`itinerary-tabpanel-${index}`}
            aria-labelledby={`itinerary-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ pr: { xs: 0, }, p: 3, pl: 0 }}>{children}</Box>}
        </div>
    );
}

TabItinerary.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `itinerary-tab-${index}`,
        'aria-controls': `itinerary-tabpanel-${index}`,
    };
}
// End of Itinerary

export default function TourDetails() {
    const { refer_other_id } = useParams();
    const [tour, setTourDetails] = useState(null);
    const [images, setImages] = useState([]);
    const [itineraries, setItineraries] = useState([]);
    const [value, setValue] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [openTravelRequest, setOpenTravelRequest] = useState(false);
    const [selectedDestination, setSelectedDestination] = useState('');

    const handleOpenTravelRequest = (destination) => {
        setSelectedDestination(destination); // Set the selected destination
        setOpenTravelRequest(true);
    };

    const handleCloseTravelRequest = () => {
        setOpenTravelRequest(false);
    };

    useEffect(() => {
        const fetchTourDetails = async () => {
            try {
                const [dataResponse, imagesResponse, itinerariesResponse] = await Promise.all([
                    fetch(`https://aaryanholidays.in/frontend/destinations/${refer_other_id}`),
                    fetch(`https://aaryanholidays.in/frontend/gallery/${refer_other_id}`),
                    fetch(`https://aaryanholidays.in/frontend/itineraries/${refer_other_id}`)
                ]);

                const data = await dataResponse.json();
                const imagesData = await imagesResponse.json();
                const itinerariesData = await itinerariesResponse.json();
                setIsLoading(false);
                setTourDetails(data);
                console.log(tour);
                console.log("hii" + tour)
                setImages(imagesData);
                setItineraries(itinerariesData);

            } catch (error) {
                console.error('Error fetching tours: ', error);
            }
        }
        fetchTourDetails();
    }, [refer_other_id]);

    const [data, setData] = useState(null);
    const [hasData, setHasData] = useState(false); // Flag to track if data exists

    useEffect(() => {
        // Fetch data from the API
        axios.get(`https://aaryanholidays.in/frontend/special-feature-package-wise/${refer_other_id}`)
            .then(response => {
                if (response.data && response.data.length > 0) {
                    setData(response.data);
                    setHasData(true); // Set the flag to true if data is present
                } else {
                    setHasData(false); // Set the flag to false if no data
                }
            })
            .catch(error => {
                console.error("Error fetching the data", error);
                setHasData(false); // Set flag to false in case of error
            });
    }, [refer_other_id]);

    // Example truemonth string

    // Base JSON data
    const monthsData = [
        { id: 1, title: 'January', initial: 'J' },
        { id: 2, title: 'February', initial: 'F' },
        { id: 3, title: 'March', initial: 'M' },
        { id: 4, title: 'April', initial: 'A' },
        { id: 5, title: 'May', initial: 'M' },
        { id: 6, title: 'June', initial: 'J' },
        { id: 7, title: 'July', initial: 'J' },
        { id: 8, title: 'August', initial: 'A' },
        { id: 9, title: 'September', initial: 'S' },
        { id: 10, title: 'October', initial: 'O' },
        { id: 11, title: 'November', initial: 'N' },
        { id: 12, title: 'December', initial: 'D' },
    ];
    console.log("hii" + tour)
    const truemonth = tour?.location || ''; // Use the correct field name from the API response
    const truemonthArray = truemonth.split(',').map(month => month.trim());

    // Create new array with `best` property
    const bestTimeToVisit = monthsData.map(month => ({
        ...month,
        best: truemonthArray.includes(month.title),
    }));

    console.log(bestTimeToVisit);
    const renderIcons = (text) => {
        const iconData = parseIcons(text);
        return (
            <List sx={{ display: 'flex' }}>
                {iconData.map((item, index) => {
                    const IconComponent = iconMapping[item.type];
                    if (!IconComponent) return null; // Handle cases where the icon type is not found
                    return (
                        <ListItem key={index} sx={{ flexDirection: 'column', textAlign: 'center', pt: 0 }}>
                            <Stack
                                direction={{
                                    xs: 'column',
                                    md: 'row',
                                }}
                                sx={{
                                    flexWrap: { md: 'wrap' }
                                }}>
                                <IconComponent sx={{ width: '100%', display: 'block', fontSize: GlobalTheme.typography.h3.fontSize, color: GlobalTheme.palette.common.bodyTxt, mb: 1 }} />
                                <Typography variant="body2" sx={{ width: '100%', display: 'block', color: GlobalTheme.palette.common.bodyTxt, fontWeight: 500 }}>
                                    <Stack>
                                        <Box>{item.count}</Box>
                                        {item.type}
                                    </Stack>
                                </Typography>
                            </Stack>
                        </ListItem>
                    );
                })
                }
            </List >
        );
    };

    return (

        <React.Fragment>
            <Helmet>
                <title>{tour?.banner_title ? `${tour?.banner_title} - Aaryan Holidays` : ''}</title>
                <meta name="description" content={tour?.banner_description || ""} />
                <meta name="keywords" content={tour?.keyword || ""} />
                <meta property="og:title" content={tour?.banner_title || ""} />
                <meta property="og:description" content={tour?.banner_description || ""} />
                <meta property="og:url" content={`https://aaryanholidays.com/tour-details/4/${refer_other_id}`} />
                <meta property="og:image" content={`${BASE_MEDIA_URL}${tour?.banner_src}` || ""} />


            </Helmet>
            <ThemeProvider theme={GlobalTheme}>
                <CssBaseline />
                {isLoading ? (
                    <Skeleton variant="rectangular" animation='wave' sx={{ height: 400 }} />
                ) : (
                    <DynamicMantleTourDetails
                        banner_type={tour?.banner_type}
                        banner_src={`${BASE_MEDIA_URL}${tour?.banner_src}`}
                        banner_alt={tour?.banner_alt}
                        banner_title={tour?.banner_title}
                        banner_description={tour?.banner_description}
                        price={tour?.price}
                        days={tour?.tour_days}
                        data={images}
                    />
                )}


                <Container id="tourDetails" sx={{ backgroundColor: GlobalTheme.palette.warning.light, px: { xs: 0 } }} maxWidth='xxl'>
                    <Container sx={{ px: { xs: 0, sm: 3 } }}>
                        <Grid container id='containerBroucher' display='flex' flexWrap='wrap' justifyContent={{ xs: 'space-around', sm: 'space-between' }} alignItems='center' sx={{ py: 2 }}>
                            {/* {isLoading ? (
                                <Box display='flex' justifyContent='space-around' sx={{ width: '100%' }}>
                                    <Grid item xs={12} sm={6}>
                                        <Stack direction='row' spacing={2}>
                                            <Skeleton variant="rectangular" width={100} height={50} />
                                            <Skeleton variant="rectangular" width={100} height={50} />
                                            <Skeleton variant="rectangular" width={100} height={50} />
                                            <Skeleton variant="rectangular" width={100} height={50} />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Stack direction='row' spacing={2} sx={{ alignItems: 'center' }}>
                                            <Skeleton variant="rectangular" width={200} height={30} />
                                            <Skeleton variant="rectangular" width={300} height={50} sx={{ borderRadius: 40 }} />
                                        </Stack>
                                    </Grid>
                                </Box>
                            ) : (
                                <>

                                </>
                            )} */}

                            <Grid item xs={12} md={6}>
                                {tour && renderIcons(tour.tour_features)}
                            </Grid>
                            <Grid item xs={12} md={6} sx={{ textAlign: { md: 'right' }, width: { xs: '100%', sm: 'auto' } }}>
                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={4} sx={{
                                    '& >:not(style)~:not(style)': {
                                        mt: { xs: 2.5, sm: 0 }
                                    },
                                    justifyContent: { sm: 'center' }
                                }}>
                                    {/* <Button variant="text" color='error' startIcon={<FileDownloadOutlinedIcon />}>
                                                Brochure
                                            </Button> */}

                                    <Button
                                        id='downloadBrochure'
                                        variant="text"
                                        color="error"
                                        startIcon={<FileDownloadOutlinedIcon />}
                                        onClick={() => {
                                            if (tour.upload_brochures) {
                                                const fileUrl = `${BASE_MEDIA_URL}${tour.upload_brochures}`;
                                                const link = document.createElement('a');
                                                link.href = fileUrl;
                                                link.target = "_blank";
                                                link.download = 'brochure.pdf'; // You can set a custom file name
                                                document.body.appendChild(link);
                                                link.click();
                                                document.body.removeChild(link);
                                            } else {
                                                alert('No files to download');
                                            }
                                        }}
                                    >
                                        Brochure 
                                    </Button>

                                    <Button
                                        id='bookTour'
                                        variant="contained"
                                        size='large'

                                        disableFocusRipple
                                        disableElevation
                                        endIcon={<KeyboardArrowRightOutlinedIcon />}

                                        sx={{
                                            px: { md: 3 },
                                            py: { xs: 3, md: 2 },
                                            color: GlobalTheme.palette.common.white,
                                            backgroundColor: GlobalTheme.palette.common.black,
                                            borderRadius: { xs: 0, sm: 12.5 },
                                            boxShadow: 'none',

                                            textTransform: 'uppercase',
                                            fontSize: GlobalTheme.typography.subtitle1.fontSize,
                                            transition: 'all 0.3s ease-in-out',

                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            gap: '8px',

                                            "& .MuiButton-endIcon": {
                                                opacity: 0,
                                                transform: 'translateX(-2px)', // Initially hidden and shrunk
                                                transition: 'transform 0.3s ease, opacity 0.3s ease', // Smooth transition for both transform and opacity
                                            },
                                            "&:hover .MuiButton-endIcon, &:focus .MuiButton-endIcon": {
                                                opacity: 1,
                                                transform: 'translateX(0)', // Slide in and grow to full size on hover
                                            },
                                            "& .buttonText": {
                                                transition: 'transform 0.3s ease', // Smooth transition for text
                                            },

                                            "&:hover .buttonText, &:focus .buttonText": {
                                                transform: 'translateX(-2px)', // Text moves slightly left on hover
                                            },
                                        }}
                                        onClick={() => handleOpenTravelRequest(tour?.thumbnail_heading)}
                                    >
                                        <Box component='span' className="buttonText" position='relative'
                                            sx={{
                                                display: 'inline-block',
                                                transform: 'translateX(16px)'
                                            }}>
                                            Book this Tour
                                        </Box>
                                    </Button >

                                </Stack>
                            </Grid>
                        </Grid>
                    </Container>
                </Container>

                <Container id="intro" sx={{ backgroundColor: GlobalTheme.palette.common.white }} maxWidth='xxl'>
                    {/* <Container fixed sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', py: 6 }}>
                        {isLoading ? (
                            <Box display='flex' alignItems='center' justifyContent='center' flexWrap='wrap' flexDirection='column'>
                                <Skeleton variant="rectangular" width={400} height={20} sx={{ mb: 3 }} />
                                <Skeleton variant="rectangular" width={300} height={15} sx={{ mb: 1 }} />
                                <Skeleton variant="rectangular" width={250} height={10} />
                            </Box>
                        ) : (
                            <>
                                <Typography variant='h2' component='h2' sx={{ mb: 3, display: 'block', width: '100%', textAlign: 'center' }}>{tour?.body_heading_txt}</Typography>
                                <Typography variant='subtitle2' component='p' textAlign='center' fontWeight={400}>
                                    {tour?.body_txt}
                                </Typography>
                            </>
                        )}
                    </Container> */}
                </Container>

                <Container id="funAndAdventure" sx={{ backgroundColor: GlobalTheme.palette.common.white, py: 6 }} maxWidth='xxl'>
                    <Container fixed>
                        {isLoading ? (
                            <>
                                <Box display='flex' alignItems='center' justifyContent='center' flexWrap='wrap' flexDirection='column'>
                                    <Skeleton variant="rectangular" width={400} height={20} sx={{ mb: 3 }} />
                                </Box>

                                <Stack direction='row' spacing={3}>
                                    <Skeleton variant="rectangular" width={150} height={30} />
                                    <Skeleton variant="rectangular" width={150} height={30} />
                                    <Skeleton variant="rectangular" width={150} height={30} />
                                </Stack>
                                <Skeleton variant="rectangular" width={'100%'} height={1} sx={{ mb: 2 }} />
                                <Box display='flex' justifyContent='space-between' sx={{ width: '100%' }}>
                                    <Grid item xs={12} sm={6}>
                                        <Skeleton variant="rectangular" width={500} height={15} sx={{ mb: 2 }} />
                                        <Skeleton variant="rectangular" width={200} height={10} sx={{ mb: 1 }} />
                                        <Skeleton variant="rectangular" width={150} height={10} sx={{ mb: 1 }} />
                                        <Skeleton variant="rectangular" width={100} height={10} />

                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Skeleton variant="rectangular" width={500} height={300} sx={{ borderRadius: 8, mb: 3 }} />
                                    </Grid>
                                </Box>
                            </>
                        ) : (
                            <>
                                <Grid sx={{ textAlign: 'center' }}>
                                    <Typography variant='h2' component='h2' gutterBottom>All day Fun &amp; adventure</Typography>
                                </Grid>

                                <Box sx={{ width: '100%' }}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <Tabs id='tourTbs' value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto" aria-label="vacation itinerary">
                                            {itineraries.map((itinerary, index) => (
                                                <Tab key={itinerary.id} label={`Day ${index + 1}`} {...a11yProps(index)} />
                                            ))}
                                        </Tabs>
                                    </Box>
                                    {itineraries.map((itinerary, index) => (
                                        <TabItinerary key={itinerary.id} value={value} index={index}>
                                            <Grid container spacing={2} sx={{ flexWrap: { xs: 'wrap', md: 'nowrap' } }}>
                                                <Grid item xs={12} md={6} sx={{ maxHeight: { md: 440 }, overflowY: 'auto', pr: { sm: 3 } }}>
                                                    <Typography variant='h4' component='h4' sx={{ textTransform: 'uppercase', mb: 2 }} > {itinerary.description_heading}</Typography>
                                                    <Typography variant='subtitle2' component='p' sx={{ mb: 2, color: GlobalTheme.palette.common.bodyTxt }}>
                                                        {itinerary.description}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <img src={`https://aaryanholidays.in/express/uploads/${itinerary.image}`} alt='' style={{ width: '100%', maxWidth: 653, height: 'auto', borderRadius: 8 }} />
                                                </Grid>
                                            </Grid>
                                        </TabItinerary>
                                    ))}
                                </Box>
                            </>
                        )}
                    </Container>
                </Container>

                <Container id="bestTimeToVisit" sx={{
                    backgroundColor: GlobalTheme.palette.common.grayLight,
                    py: 5
                }} maxWidth='xxl'>
                    <Container fixed sx={{
                        display: 'flex',
                        alignContent: 'center',
                        justifyContent: 'space-between'
                    }}>
                        <Grid container spacing={3}>
                            {isLoading ? (
                                <>
                                    <Box display='flex' alignItems='center' justifyContent='center' flexWrap='wrap' flexDirection='column'>
                                        <Skeleton variant="rectangular" width={200} height={15} sx={{ my: 3 }} />
                                    </Box>

                                    <Box display='flex' justifyContent='space-between' sx={{ width: '100%' }}>
                                        <Grid item xs={12} sm={6}>
                                            <Stack direction='row' spacing={3}>
                                                <Skeleton variant="circular" width={50} height={50} sx={{ mb: 2 }} />
                                                <Skeleton variant="circular" width={50} height={50} sx={{ mb: 2 }} />
                                                <Skeleton variant="circular" width={50} height={50} sx={{ mb: 2 }} />
                                                <Skeleton variant="circular" width={50} height={50} sx={{ mb: 2 }} />
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Skeleton variant="rectangular" width={'100%'} height={300} sx={{ borderRadius: 8, mb: 3 }} />
                                        </Grid>
                                    </Box>
                                </>
                            ) : (
                                <>
                                    <Grid item xs={12} md={8}>

                                        <Typography variant='h3' component='h3' sx={{ mb: 3 }}>BEST TIME TO VISIT</Typography>

                                        <List
                                            sx={{
                                                display: 'inline-flex',
                                                flexWrap: 'wrap',
                                                mb: 3
                                            }}>
                                            {bestTimeToVisit.map(month => (
                                                <Tooltip title={month.title}>
                                                    <ListItem
                                                        id={`month${month.id}`}
                                                        key={month.id}
                                                        sx={{
                                                            mr: 2,
                                                            fontSize: GlobalTheme.typography.subtitle2.fontSize,
                                                            backgroundColor: month.best ? GlobalTheme.palette.primary.main : GlobalTheme.palette.warning.light,
                                                            color: month.best ? GlobalTheme.palette.common.white : GlobalTheme.palette.primary.main,
                                                            border: '1px solid',
                                                            borderColor: month.best ? GlobalTheme.palette.primary.white : GlobalTheme.palette.primary.main,
                                                            borderRadius: 50,
                                                            py: 1.25, mb: 2,
                                                            width: 'auto'
                                                        }}
                                                    >
                                                        {month.initial}
                                                    </ListItem>
                                                </Tooltip>
                                            ))}
                                        </List>
                                        <Typography variant='subtitle2' component='p' sx={{
                                            fontWeight: 400
                                        }}>
                                            {tour?.best_time_text}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} md={4}>
                                        {tour?.map ? (

                                            <iframe
                                                title='tour map'
                                                src={tour?.map}
                                                width='100%'
                                                height='300'
                                                allowFullScreen
                                                loading="lazy"
                                                referrerPolicy="no-referrer-when-downgrade"
                                                style={{ border: 'none' }}
                                            ></iframe>

                                        ) : null}
                                    </Grid>
                                </>
                            )}

                        </Grid>
                    </Container>
                </Container>


                {
                    hasData && (
                        <Container fixed sx={{ py: 5 }}>
                            <HotelsShoppingKidsTabs />
                        </Container>
                    )
                }
                <TravelRequestDialog open={openTravelRequest} onClose={handleCloseTravelRequest} destination={selectedDestination} />
            </ThemeProvider >
        </React.Fragment >
    );
}
