import React from 'react';
import DOMPurify from 'dompurify';

function DisplayQuillContent({ content }) {
  // Sanitize the HTML content
  const sanitizedContent = DOMPurify.sanitize(content);

  return (
    <div
      dangerouslySetInnerHTML={{ __html: sanitizedContent }}
    />
  );
}

export default DisplayQuillContent;
