import React from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import { CardActionArea, Typography, alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import HouseboatOutlinedIcon from '@mui/icons-material/HouseboatOutlined';
import LuggageOutlinedIcon from '@mui/icons-material/LuggageOutlined';
import AirplaneTicketOutlinedIcon from '@mui/icons-material/AirplaneTicketOutlined';
import HotelOutlinedIcon from '@mui/icons-material/HotelOutlined';
import DriveEtaOutlinedIcon from '@mui/icons-material/DriveEtaOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import CardMembershipOutlinedIcon from '@mui/icons-material/CardMembershipOutlined';
import RedeemOutlinedIcon from '@mui/icons-material/RedeemOutlined';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { WOW } from 'wowjs';

import AboutImg from './assets/images/about.png';
import { ThemeProvider } from '@mui/material/styles';
import { GlobalTheme } from '../../theme';

import partnersImg from './assets/images/together.png';
import AaryanTimeline from './timeline';
import BGTravel from './assets/images/bg.jpeg';

import { useState, useEffect } from 'react';
import axios from 'axios';
import DynamicMantle from '../../components/mantles/DynamicMantle';
import './assets/scss/style.scss';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles
import DisplayQuillContent from '../../components/DisplayQuillContent';
const BASE_MEDIA_URL = 'https://aaryanholidays.in/express/uploads/'; // Update this to the correct base URL if needed

// const offer = [
//     {
//         id: 1,
//         icon: <HouseboatOutlinedIcon sx={{ fontSize: '6rem', fill: 'none', stroke: 'currentcolor', strokeWidth: '.03rem' }} />,
//         title: 'Indian<br/>Holiday',
//         brief: 'Immigration advisory visa a foundation was establishe with a  ideaImmigration advisory visa a foundation'
//     },
//     {
//         id: 2,
//         icon: <LuggageOutlinedIcon sx={{ fontSize: '6rem', fill: 'none', stroke: 'currentcolor', strokeWidth: '.03rem' }} />,
//         title: 'International<br/>Holiday',
//         brief: 'Immigration advisory visa a foundation was establishe with a  ideaImmigration advisory visa a foundation'
//     }
// ]
// const offerCont = [
//     {
//         id: 3,
//         icon: <AirplaneTicketOutlinedIcon sx={{ fontSize: '6rem', fill: 'none', stroke: 'currentcolor', strokeWidth: '.03rem' }} />,
//         title: 'Ait Ticket<br/>& Rail Ticket',
//         brief: 'Immigration advisory visa a foundation was establishe with a  ideaImmigration advisory visa a foundation'
//     },
//     {
//         id: 4,
//         icon: <HotelOutlinedIcon sx={{ fontSize: '6rem', fill: 'none', stroke: 'currentcolor', strokeWidth: '.03rem' }} />,
//         title: 'Hotel<br/>Booking',
//         brief: 'Immigration advisory visa a foundation was establishe with a  ideaImmigration advisory visa a foundation'
//     },
//     {
//         id: 5,
//         icon: <DriveEtaOutlinedIcon sx={{ fontSize: '6rem', fill: 'none', stroke: 'currentcolor', strokeWidth: '.03rem' }} />,
//         title: 'Car<br/>Rental',
//         brief: 'Immigration advisory visa a foundation was establishe with a  ideaImmigration advisory visa a foundation'
//     },
//     {
//         id: 6,
//         icon: <ArticleOutlinedIcon sx={{ fontSize: '6rem', fill: 'none', stroke: 'currentcolor', strokeWidth: '.03rem' }} />,
//         title: 'Passport<br/>Visa',
//         brief: 'Immigration advisory visa a foundation was establishe with a  ideaImmigration advisory visa a foundation'
//     }

// ]



export default function About({ home }) {
    const [animate, setAnimate] = useState(false);
    const [mantleData, setMantleData] = useState(null);
    const [slidesData, setslidesData] = useState([]);
    const [offer, setOffer] = useState([]);
    const [offerCont, setOfferCont] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [achievements, setAchievements] = useState([
        {
            id: 1,
            icon: <CardMembershipOutlinedIcon sx={{ fontSize: '6rem', fill: 'none', stroke: 'currentcolor', strokeWidth: '.03rem' }} />,
            title: 'Accredited<br/>Membership',
            brief: 'Immigration advisory visa a foundation was establishe with a ideaImmigration advisory',
            pdf: ''  // Placeholder for the PDF link
        },
        {
            id: 2,
            icon: <EmojiEventsOutlinedIcon sx={{ fontSize: '6rem', fill: 'none', stroke: 'currentcolor', strokeWidth: '.03rem' }} />,
            title: 'Our<br/>Achievements',
            brief: 'Immigration advisory visa a foundation was establishe with a ideaImmigration advisory',
            pdf: ''  // Placeholder for the PDF link
        },
        {
            id: 3,
            icon: <RedeemOutlinedIcon sx={{ fontSize: '6rem', fill: 'none', stroke: 'currentcolor', strokeWidth: '.03rem' }} />,
            title: 'Our<br/>Certifications',
            brief: 'Immigration advisory visa a foundation was establishe with a ideaImmigration advisory',
            pdf: ''  // Placeholder for the PDF link
        }
    ]);

    useEffect(() => {
        // Fetch data from the API
        axios.get('https://aaryanholidays.in/backend/about-mantle')
            .then((response) => {
                if (response.data && response.data.length > 0) {
                    const data = response.data[0];
                    setMantleData(data); // Assuming you want to use the first object in the array

                    // Update the achievements array with the corresponding PDF values
                    setAchievements((prevAchievements) => prevAchievements.map((achievement) => {
                        if (achievement.id === 1) {
                            return { ...achievement, pdf: `${BASE_MEDIA_URL}${data.pdf_membership_pdf}` };
                        } else if (achievement.id === 2) {
                            return { ...achievement, pdf: `${BASE_MEDIA_URL}${data.pdf_achievements_pdf}` };
                        } else if (achievement.id === 3) {
                            return { ...achievement, pdf: `${BASE_MEDIA_URL}${data.pdf_certification_pdf}` };
                        }
                        return achievement;
                    }));
                }
            })
            .catch((error) => {
                console.error('Error fetching the mantle data:', error);
            });
    }, []);
    const iconsMap = [
        <HouseboatOutlinedIcon sx={{ fontSize: '6rem', fill: 'none', stroke: 'currentcolor', strokeWidth: '.03rem' }} />,
        <LuggageOutlinedIcon sx={{ fontSize: '6rem', fill: 'none', stroke: 'currentcolor', strokeWidth: '.03rem' }} />,
        <AirplaneTicketOutlinedIcon sx={{ fontSize: '6rem', fill: 'none', stroke: 'currentcolor', strokeWidth: '.03rem' }} />,
        <HotelOutlinedIcon sx={{ fontSize: '6rem', fill: 'none', stroke: 'currentcolor', strokeWidth: '.03rem' }} />,
        <DriveEtaOutlinedIcon sx={{ fontSize: '6rem', fill: 'none', stroke: 'currentcolor', strokeWidth: '.03rem' }} />,
        <ArticleOutlinedIcon sx={{ fontSize: '6rem', fill: 'none', stroke: 'currentcolor', strokeWidth: '.03rem' }} />
    ];
    useEffect(() => {
        const fetchAbout = axios.get(`https://aaryanholidays.in/backend/table-data/aaryaan_about`);

        Promise.all([fetchAbout])
            .then(([AboutResponse]) => {

                const filteredData = AboutResponse.data.data.filter(about => about.id !== 1); // Filter out records where id === 1
                setslidesData(filteredData);
                setIsLoading(false);
                const data = filteredData[0]; // Assuming one record for simplicity

                // Prepare the `offer` array
                const offerData = [];
                for (let i = 1; i <= 2; i++) {
                    offerData.push({
                        id: i,
                        icon: iconsMap[i - 1], // Dynamically select icons
                        title: data[`offer${i}_title`],
                        brief: data[`offer${i}_description`]
                    });
                }
                setOffer(offerData);

                // Prepare the `offerCont` array
                const offerContData = [];
                for (let i = 3; i <= 6; i++) {
                    offerContData.push({
                        id: i,
                        icon: iconsMap[i - 1], // Dynamically select icons
                        title: data[`offer${i}_title`],
                        brief: data[`offer${i}_description`]
                    });
                }
                setOfferCont(offerContData);

            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, []);

    useEffect(() => {
        // Trigger animation once the component mounts
        const timeout = setTimeout(() => {
            setAnimate(true);
        }, 0); // Delay can be adjusted as needed
        return () => clearTimeout(timeout); // Cleanup
    }, []);

    useEffect(() => {
        const wow = new WOW({
            boxClass: 'wow',      // Class name to trigger animations (default: 'wow')
            animateClass: 'animate__animated', // CSS class for animations (default: 'animated')
            offset: 100,           // Distance to the element when triggering the animation
            mobile: true,        // Enable animations on mobile devices
            live: true,          // Check for new elements in the DOM
        });
        wow.init();
    }, []); // Empty dependency array ensures it runs once


    // Check if mantleData is null
    const downloadPDF = (pdfUrl) => {
        if (pdfUrl) {
            const link = document.createElement('a');
            link.href = pdfUrl;
            link.target = '_blank'; // Open in a new tab
            link.rel = 'noopener noreferrer'; // For security (optional, best practice)

            // If the user wants to download it directly, they can use the "Save link as..." option from the context menu
            // Or use the browser's built-in keyboard shortcut like Alt+Click (or Cmd/Ctrl + Click depending on the browser)
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            console.error('No PDF available.');
        }
    };
    if (!mantleData) {
        return <div>Loading...</div>;
    }
    function stripHtml(html) {
        if (!html) return '';
        return html.replace(/<\/?[^>]+(>|$)/g, ''); // Removes all HTML tags
    }
    return (
        <React.Fragment>
            <ThemeProvider theme={GlobalTheme}>
                <CssBaseline />
                <DynamicMantle
                    banner_type={'image'}
                    banner_src={`${BASE_MEDIA_URL}${slidesData[0]?.image_banner_image}`}
                    banner_alt={stripHtml(slidesData[0]?.banner_title)}
                    banner_title={stripHtml(slidesData[0]?.banner_title)}
                    banner_description={stripHtml(slidesData[0]?.banner_description)}
                    isLoading={isLoading}
                />
                {slidesData.length > 0 && (
                    <>
                        {slidesData.map((item, index) => (
                            <>
                                <Container fixed component='section' aria-labelledby='companyHeading' className={animate ? 'animate__animated animate__fadeIn animate__delay-1s' : ''}>
                                    <Grid display='flex' alignItems='center' flexWrap={{ xs: 'wrap', lg: 'nowrap' }} sx={{ my: 8 }}>
                                        <Grid item xs={12} sm={6} sx={{ pr: 3 }}>
                                            <Typography id='companyHeading' component='h2' variant='h2' sx={{ mb: 3 }} > <DisplayQuillContent content={item.content_title || 'No Title'} /></Typography>
                                            <Typography component='p' variant='subtitle1' sx={{ color: GlobalTheme.palette.common.bodyTxt }}>
                                                <DisplayQuillContent content={item.content_description || 'No Title'} />

                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <img src={`${BASE_MEDIA_URL}${item.image_content_image}`} alt='' style={{ maxWidth: 400 }} className='imgFLuid' />
                                        </Grid>
                                    </Grid>
                                </Container>


                                <Container maxWidth="xxl" component='section' aria-labelledby='missionVisionHeading'
                                    sx={{
                                        backgroundImage: `url(${BGTravel})`,
                                        backgroundSize: 'cover',
                                        backgroundAttachment: 'fixed',
                                        backgroundPosition: 'center',
                                        py: 10

                                    }}>
                                    <Container fixed>
                                        <Grid
                                            className={animate ? 'wow animate__animated animate__fadeInDown animate__delay-1s' : ''}
                                            sx={{
                                                textAlign: 'left',
                                                pr: {
                                                    md: 20,
                                                    lg: 70
                                                }
                                            }}>
                                            <Typography id='missionVisionHeading' variant='h2' component='h3' sx={{ mb: 3, color: GlobalTheme.palette.common.white }} > <DisplayQuillContent content={item.vision_mission_heading || 'No Title'} /></Typography>

                                            <Typography variant='subtitle2' component='span' fontWeight={300} sx={{ color: GlobalTheme.palette.common.white }}
                                            >
                                                <DisplayQuillContent content={item.vision_mission_description || 'No Title'} />
                                            </Typography>


                                        </Grid>
                                    </Container>
                                </Container>

                                <Container maxWidth="xxl" component='section' aria-labelledby='travelSpecialistHeading whoWeAreHeading'
                                    sx={{
                                        backgroundColor: GlobalTheme.palette.common.grayLight
                                    }}
                                >
                                    <Container fixed sx={{ py: 10 }}>
                                        <Box display='flex' alignItems="stretch" flexWrap={{ xs: 'wrap', lg: 'nowrap' }}>
                                            <Grid item xs={12} sm={6}>
                                                <img src={`${BASE_MEDIA_URL}${item.image_travel_special_image1}`} alt='' style={{ maxWidth: 500 }} className='imgFLuid' />
                                            </Grid>


                                            <Grid item xs={12} sm={6} sx={{
                                                px: { lg: 5 },
                                                mt: { xs: 2, lg: 0 }
                                            }}>
                                                <Typography id='travelSpecialistHeading' component='h4' variant='h3' color='primary' gutterBottom sx={{ textTransform: 'uppercase' }}>
                                                    Travel <br />Specialist
                                                </Typography>
                                                <Typography id='whoWeAreHeading' component='h2' variant='h2' sx={{ mb: { xs: 2, lg: 5 } }}>
                                                    <DisplayQuillContent content={item.travel_specialist_heading || 'No Title'} />
                                                </Typography>
                                                <Typography variant='subtitle2' component='p' sx={{ color: GlobalTheme.palette.common.bodyTxt }}>
                                                    <DisplayQuillContent content={item.travel_specialist_description1 || 'No Title'} />
                                                    <DisplayQuillContent content={item.travel_specialist_description2 || 'No Title'} />
                                                </Typography>
                                            </Grid>

                                        </Box>
                                    </Container>
                                </Container>

                                <Container fixed sx={{ py: 10 }} component='section' aria-labelledby='ourJourneyHeading'>
                                    <Grid>
                                        <Typography id='ourJourneyHeading' component='h2' variant='h2' sx={{ mb: 2, fontWeight: 600, textAlign: 'center' }}>Our Journey</Typography>
                                        <AaryanTimeline />
                                    </Grid>

                                </Container>

                                <Container maxWidth="xxl" component='section' aria-labelledby='ourServiceHeading whatWeOfferHeading'
                                    sx={{
                                        backgroundColor: GlobalTheme.palette.primary.main
                                    }}
                                >
                                    <Container fixed sx={{ py: 10 }}>
                                        <Grid item xs display='flex' spacing={3} justifyContent='space-between' flexWrap='wrap'>

                                            <Grid container spacing={3}>
                                                <Grid item xs={12} sm={6} display='flex' flexDirection='column' justifyContent='flex-end' sx={{ mb: 6 }}>
                                                    <Typography id='ourServiceHeading' component='h4' variant='h4' sx={{ textTransform: 'uppercase', fontWeight: 600, mb: 1 }}>
                                                        Our <br />Services
                                                    </Typography>
                                                    <Typography id='whatWeOfferHeading' component='h3' variant='h2' color='white' sx={{ textTransform: 'none' }}>
                                                        What We Offer For You <br />In Great Packages
                                                    </Typography>
                                                </Grid>

                                                {offer.map((item, index) => (
                                                    <Grid key={item} id={`holiday${item.id}`} item xs={12} sm={3}
                                                        sx={{
                                                            mb: 3,
                                                            position: 'relative',
                                                            overflow: 'hidden',
                                                            borderRadius: 2,
                                                            width: 258,
                                                            height: 348,
                                                        }}>
                                                        <Card elevation={0}
                                                            sx={{
                                                                borderRadius: 2,
                                                                textAlign: 'center',
                                                                height: '100%',
                                                                backgroundColor: 'transparent',
                                                                border: '1px solid',
                                                                borderColor: GlobalTheme.palette.common.white,
                                                                color: GlobalTheme.palette.common.white,
                                                                transition: 'all .2s ease-in',
                                                                "&:hover": {
                                                                    backgroundColor: GlobalTheme.palette.common.white,
                                                                    color: GlobalTheme.palette.common.black
                                                                },

                                                                "&:hover .briefText": {
                                                                    pt: 23
                                                                }



                                                            }}>
                                                            <CardContent sx={{
                                                                height: '100%',
                                                                position: 'absolute',
                                                                pt: 12.5,
                                                                transition: 'all .4s ease-in',

                                                                "&:hover": {
                                                                    pt: 6.25
                                                                },

                                                                "&:hover .briefText": {
                                                                    pt: 1.25
                                                                }

                                                            }}>
                                                                {item.icon}
                                                                <Typography gutterBottom variant="h4" component="h4">
                                                                    <span dangerouslySetInnerHTML={{ __html: item.title }} />
                                                                </Typography>
                                                                <Typography variant="body2" component='p' sx={{ pt: 12.5 }} className='briefText'

                                                                    dangerouslySetInnerHTML={{ __html: item.brief }} >
                                                                </Typography>

                                                            </CardContent>

                                                        </Card>
                                                    </Grid>
                                                ))}
                                            </Grid>

                                            <Grid container spacing={3}>

                                                {offerCont.map((item, index) => (
                                                    <Grid key={item} id={`holiday${item.id}`} item xs={12} sm={3}
                                                        sx={{
                                                            mb: 3,
                                                            position: 'relative',
                                                            overflow: 'hidden',
                                                            borderRadius: 2,
                                                            width: 258,
                                                            height: 348,
                                                        }}>
                                                        <Card elevation={0}
                                                            sx={{
                                                                borderRadius: 2,
                                                                textAlign: 'center',
                                                                height: '100%',
                                                                backgroundColor: 'transparent',
                                                                border: '1px solid',
                                                                borderColor: GlobalTheme.palette.common.white,
                                                                color: GlobalTheme.palette.common.white,
                                                                transition: 'all .2s ease-in',
                                                                "&:hover": {
                                                                    backgroundColor: GlobalTheme.palette.common.white,
                                                                    color: GlobalTheme.palette.common.black
                                                                },

                                                                "&:hover .briefText": {
                                                                    pt: 23
                                                                }



                                                            }}>
                                                            <CardContent sx={{
                                                                height: '100%',
                                                                position: 'absolute',
                                                                pt: 12.5,
                                                                transition: 'all .4s ease-in',

                                                                "&:hover": {
                                                                    pt: 6.25
                                                                },

                                                                "&:hover .briefText": {
                                                                    pt: 1.25
                                                                }

                                                            }}>
                                                                {item.icon}
                                                                <Typography gutterBottom variant="h4" component="h4">
                                                                    <span dangerouslySetInnerHTML={{ __html: item.title }} />
                                                                </Typography>
                                                                <Typography variant="body2" component='p' sx={{ pt: 12.5 }} className='briefText' dangerouslySetInnerHTML={{ __html: item.brief }} >
                                                                </Typography>
                                                            </CardContent>

                                                        </Card>
                                                    </Grid>
                                                ))}

                                            </Grid>
                                        </Grid>
                                    </Container>
                                </Container>

                                <Container maxWidth="xxl" component='section' aria-labelledby='ourPrestigeHeading whatWeOfferHeading'
                                    sx={{
                                        backgroundColor: GlobalTheme.palette.common.grayLight
                                    }}
                                >
                                    <Container fixed sx={{ py: 10 }}>
                                        <Grid sx={{ textAlign: 'center', mb: 5 }}>
                                            <Typography id='ourPrestigeHeading' component='h4' color='primary' variant='h4' sx={{ textTransform: 'uppercase', fontWeight: 600, mb: 1 }}>
                                                Our Prestige
                                            </Typography>
                                            <Typography id='whatWeOfferHeading' component='h3' variant='h2' sx={{ textTransform: 'none' }}>
                                                RECOGNITION &  ACHIEVEMENTS
                                            </Typography>
                                        </Grid>

                                        <Stack direction={{ xs: 'column', md: 'row' }} spacing={5}>

                                            {achievements.map((item, index) => (
                                                <Card elevation={0} key={item} id={`holiday${item.id}`}
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        borderRadius: 2,
                                                        width: '100%',
                                                        height: 348,
                                                        alignItems: 'center',
                                                        textAlign: 'center',
                                                        md: 2,
                                                        backgroundColor: GlobalTheme.palette.common.white,

                                                        "&:hover": {
                                                            backgroundColor: GlobalTheme.palette.primary.main,
                                                            color: GlobalTheme.palette.common.white
                                                        }
                                                    }}>
                                                    <CardContent
                                                        sx={{
                                                            transition: 'all .4s ease-in',

                                                            "&:hover": {
                                                                pt: .25
                                                            }
                                                        }}
                                                    >
                                                        <CardActionArea onClick={() => downloadPDF(item.pdf)}>
                                                            {item.icon}
                                                            <Typography gutterBottom variant="h4" component="h4" fontWeight="500">
                                                                <span dangerouslySetInnerHTML={{ __html: item.title }} />
                                                            </Typography>
                                                        </CardActionArea>
                                                    </CardContent>
                                                </Card>

                                            ))}
                                        </Stack>
                                    </Container>
                                </Container>
                            </>
                        ))}
                    </>
                )
                }


            </ThemeProvider>
        </React.Fragment >
    )
}