import React from "react";
import { Box, Typography, Button } from "@mui/material";

const TermsAndConditions = () => {
    return (
        <div>

            <Typography id="terms-description" variant="subtitle2" fontWeight={200} component="div">
                <p><strong>Last updated: November 23, 2024</strong></p>
                <p>
                    Please read these terms and conditions carefully before using Our
                    Service.
                </p>
                <Typography variant='h4' component='h2'>Interpretation and Definitions</Typography>
                <h4>Interpretation</h4>
                <p>
                    The words of which the initial letter is capitalized have meanings
                    defined under the following conditions. The following definitions
                    shall have the same meaning regardless of whether they appear in
                    singular or in plural.
                </p>
                <h4>Definitions</h4>
                <ul>
                    <li>
                        <strong>Affiliate</strong> means an entity that controls, is
                        controlled by or is under common control with a party, where
                        "control" means ownership of 50% or more of the shares, equity
                        interest or other securities entitled to vote for election of
                        directors or other managing authority.
                    </li>
                    <li>
                        <strong>Country</strong> refers to: West Bengal, India
                    </li>
                    <li>
                        <strong>Company</strong> (referred to as either "the Company",
                        "We", "Us" or "Our" in this Agreement) refers to Aaryan Leisure
                        & Holidays Pvt. Ltd., Flat No. 1B, First Floor, Edcon Swastika
                        Building, 7/1C, Hazra Rd, Bakul Bagan, Kalighat, Kolkata, West
                        Bengal 700026.
                    </li>
                    <li>
                        <strong>Device</strong> means any device that can access the
                        Service such as a computer, a cellphone or a digital tablet.
                    </li>
                    <li>
                        <strong>Service</strong> refers to the Website.
                    </li>
                    <li>
                        <strong>Terms and Conditions</strong> (also referred as "Terms")
                        mean these Terms and Conditions that form the entire agreement
                        between You and the Company regarding the use of the Service.
                    </li>
                    <li>
                        <strong>Third-party Social Media Service</strong> means any
                        services or content (including data, information, products or
                        services) provided by a third-party that may be displayed,
                        included or made available by the Service.
                    </li>
                    <li>
                        <strong>Website</strong> refers to Aaryan Leisure & Holidays
                        Pvt. Ltd., accessible from{" "}
                        <a href="https://www.aaryanholidays.com" target="_blank" rel="noreferrer">
                            www.aaryanholidays.com
                        </a>
                    </li>
                    <li>
                        <strong>You</strong> means the individual accessing or using the
                        Service, or the company, or other legal entity on behalf of
                        which such individual is accessing or using the Service, as
                        applicable.
                    </li>
                </ul>
                <Typography variant='h4' component='h2'>Acknowledgment</Typography>
                <p>
                    These are the Terms and Conditions governing the use of this
                    Service and the agreement that operates between You and the
                    Company. These Terms and Conditions set out the rights and
                    obligations of all users regarding the use of the Service.
                </p>
                <p>
                    Your access to and use of the Service is conditioned on Your
                    acceptance of and compliance with these Terms and Conditions.
                </p>
                <Typography variant='h4' component='h2'>Links to Other Websites</Typography>
                <p>
                    Our Service may contain links to third-party web sites or services
                    that are not owned or controlled by the Company.
                </p>
                <p>
                    We strongly advise You to read the terms and conditions and
                    privacy policies of any third-party web sites or services that You
                    visit.
                </p>
                <Typography variant='h4' component='h2'>Termination</Typography>
                <p>
                    We may terminate or suspend Your access immediately, without prior
                    notice or liability, for any reason whatsoever, including without
                    limitation if You breach these Terms and Conditions.
                </p>
                <Typography variant='h4' component='h2'>Contact Us</Typography>
                <p>
                    If you have any questions about these Terms and Conditions, You
                    can contact us:
                </p>
                <ul>
                    <li>
                        By email:{" "}
                        <a href="mailto:helpdesk@aaryanholidays.com">
                            helpdesk@aaryanholidays.com
                        </a>
                    </li>
                    <li>
                        By visiting this page on our website:{" "}
                        <a
                            href="https://www.aaryanholidays.com/contact"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Contact Us
                        </a>
                    </li>
                    <li>By phone number: 7044076004</li>
                </ul>
            </Typography>
        </div>
    );
};

export default TermsAndConditions;
