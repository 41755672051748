import React, { useEffect, useState, useRef } from 'react';
import CssBaseline from '@mui/material/CssBaseline';

import { alpha } from '@mui/material';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import { ThemeProvider } from '@mui/material/styles';
import { GlobalTheme } from '../../theme';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import './assets/scss/style.scss';
import { WOW } from 'wowjs';

const BASE_MEDIA_URL = 'https://aaryanholidays.in/express/uploads/'; // Update this to the correct base URL if needed

export default function CustomizedTimeline() {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [journeyData, setJourneyData] = useState([]);
    const timelineRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            const position = window.scrollY + window.innerHeight;
            setScrollPosition(position);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        axios
            .get(`https://aaryanholidays.in/backend/table-data/aaryaan_about`)
            .then((response) => {
                const data = response.data.data[0]; // Assuming only one record
                const journeyBlocks = Object.keys(data)
                    .filter((key) => key.startsWith('our_journey_block')) // Get all journey blocks
                    .reduce((result, key) => {
                        const blockNumber = key.match(/\d+/)?.[0]; // Extract block number
                        if (!result[blockNumber]) result[blockNumber] = {};
                        if (key.includes('heading')) result[blockNumber].heading = data[key];
                        if (key.includes('description')) result[blockNumber].description = data[key];
                        return result;
                    }, {});

                // Return a Promise to ensure the next `.then()` waits for state update
                return new Promise((resolve) => {
                    setJourneyData(Object.values(journeyBlocks)); // Convert blocks into an array
                    resolve();
                });
            })
            .then(() => {
                // Initialize WOW.js after the journeyData state is updated
                const wow = new WOW({
                    boxClass: 'wow', // Class name to trigger animations
                    animateClass: 'animate__animated', // CSS class for animations
                    offset: 100, // Distance to the element when triggering the animation
                    mobile: true, // Enable animations on mobile devices
                    live: true, // Check for new elements in the DOM
                });
                wow.init();
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, []);
    useEffect(() => {
        if (journeyData.length > 0) {
            // Initialize WOW.js only after journeyData is updated
            const wow = new WOW({
                boxClass: 'wow', // Class name to trigger animations
                animateClass: 'animate__animated', // CSS class for animations
                offset: 100, // Distance to the element when triggering the animation
                mobile: true, // Enable animations on mobile devices
                live: true, // Check for new elements in the DOM
            });
            wow.init();
            wow.sync(); // Ensure WOW.js detects any new DOM changes
        }
    }, [journeyData]); // Trigger this effect whenever journeyData updates

    const isInViewport = (element) => {
        if (!element) return false;
        const rect = element.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    };
    useEffect(() => {
        const elements = document.querySelectorAll('.wow');
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.style.visibility = 'visible'; // Make visible
                        entry.target.classList.add('animate__fadeIn');
                        observer.unobserve(entry.target);
                    }
                });
            },
            { threshold: 0.1 } // Trigger when 10% of the element is visible
        );

        elements.forEach((el) => {
            el.style.visibility = 'hidden'; // Initially hide all WOW elements
            observer.observe(el);
        });

        // Cleanup observer on component unmount
        return () => observer.disconnect();
    }, []);



    useEffect(() => {
        const options = {
            root: null, // Use the viewport as the root
            threshold: 0.1, // Trigger when 10% of the element is visible
        };

        const callback = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('animate__fadeInRight'); // Add animation class
                    entry.target.style.visibility = 'visible'; // Ensure visibility
                }
            });
        };

        const observer = new IntersectionObserver(callback, options);

        // Observe all timeline content elements
        const elements = document.querySelectorAll('.timeline-content');
        elements.forEach((el) => {
            el.style.visibility = 'hidden'; // Ensure hidden initially
            observer.observe(el);
        });

        return () => {
            observer.disconnect(); // Clean up observer on unmount
        };
    }, []);
    return (
        <React.Fragment>
            <ThemeProvider theme={GlobalTheme}>
                <CssBaseline />
                <Timeline position="alternate" ref={timelineRef} sx={{
                    '& li': {
                        display: 'flex',
                        flexDirection: {
                            xs: 'column', md: 'row'
                        }
                    },
                    '& .MuiTimelineItem-root:nth-of-type(even)': {
                        flexDirection: {
                            xs: 'column-reverse', md: 'row-reverse'
                        }
                    }
                }}>
                    {journeyData.length > 0 ? (
                        journeyData.map((block, index) => (
                            <TimelineItem
                                key={index}
                                ref={(el) => {
                                    if (el && scrollPosition > el.getBoundingClientRect().top + window.scrollY) {
                                        el.classList.add('in-viewport');
                                    }
                                }}
                            >
                                <TimelineSeparator sx={{ display: { xs: 'none', md: 'flex' } }}>
                                    <TimelineConnector
                                        className={`timeline-connector ${scrollPosition > (index + 1) * 100 ? 'timeline-connector-filled' : ''}`}
                                    />
                                    <TimelineDot
                                        className={`timeline-dot ${scrollPosition > (index + 1) * 100 ? 'timeline-dot-filled' : ''}`}
                                    >
                                        <DateRangeOutlinedIcon />
                                    </TimelineDot>
                                    <TimelineConnector
                                        className={`timeline-connector ${scrollPosition > (index + 1) * 100 ? 'timeline-connector-filled' : ''}`}
                                    />
                                </TimelineSeparator>

                                <TimelineContent
                                    className={`wow animate__animated ${index % 2 === 0 ? 'animate__fadeInLeft' : 'animate__fadeInRight'}`}
                                    data-wow-delay="1s"
                                    id='sss'
                                    sx={{
                                        py: '12px', px: 2, borderRadius: 3
                                    }}>
                                    <Paper
                                        elevation={24}
                                        sx={{
                                            p: 4,
                                            boxShadow: `0 0 20px 4px ${alpha(GlobalTheme.palette.common.black, 0.1)}`,
                                        }}
                                    >
                                        <Typography
                                            // variant="h2"
                                            // component="h2"
                                            gutterBottom
                                            // sx={{ fontWeight: 600 }}
                                            dangerouslySetInnerHTML={{ __html: block.heading }}
                                        />
                                        <Typography
                                            color="common.tertiary"
                                            dangerouslySetInnerHTML={{ __html: block.description }}
                                        />
                                    </Paper>
                                </TimelineContent>
                            </TimelineItem>
                        ))
                    ) : (
                        <Typography>Loading timeline events...</Typography>
                    )}
                </Timeline>
            </ThemeProvider>
        </React.Fragment >
    );
}
