import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { GlobalTheme } from '../../theme';

const API_URL = 'https://aaryanholidays.in/frontend/testimonials';
const AVATAR_PREFIX = 'https://aaryanholidays.in/express/uploads/';

export default function App() {
    const [testimonials, setTestimonials] = useState([]);

    useEffect(() => {
        fetch(API_URL)
            .then(response => response.json())
            .then(data => {
                // Update testimonials with full avatar URLs
                const updatedTestimonials = data.map(item => ({
                    ...item,
                    avatar: `${AVATAR_PREFIX}${item.avatar}`
                }));
                setTestimonials(updatedTestimonials);
            })
            .catch(error => console.error('Error fetching testimonials:', error));
    }, []);

    return (
        <>
            <Swiper
                spaceBetween={30}
                pagination={{
                    clickable: true,
                }}
                modules={[Pagination, Autoplay]}
                style={{ paddingBottom: '40px' }}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: true,
                }}
                breakpoints={{
                    0: {
                        slidesPerView: 1,
                    },
                    600: {
                        slidesPerView: 2,

                    },
                    1536: {
                        slidesPerView: 3,
                    },
                }}
            >
                {testimonials.map((testimonial) => (
                    <SwiperSlide key={testimonial.id}>
                        <Card sx={{
                            mx: 'auto', my: 2, p: 2, borderRadius: 2,
                            textAlign: 'center',
                        }} elevation={4}>
                            <Stack direction="column" sx={{
                                flexDirection: {
                                    md: 'row'
                                }
                            }}>
                                <CardHeader
                                    sx={{
                                        flexWrap: {
                                            md: 'wrap'
                                        },
                                        flexDirection: {
                                            xs: 'column',
                                            md: 'row'
                                        },
                                        textAlign: 'center',
                                        alignContent: 'start',
                                        justifyContent: 'center',
                                        "& .MuiCardHeader-title": {
                                            fontSize: '1rem'
                                        }
                                    }}
                                    avatar={
                                        <Avatar
                                            sx={{ bgcolor: GlobalTheme.palette.secondary.main, width: 163, height: 163, mb: 2 }}
                                            aria-label=""
                                            src={testimonial.avatar}
                                        >
                                            {!testimonial.avatar.startsWith('http') && testimonial.avatar}
                                        </Avatar>
                                    }
                                    title={testimonial.title}
                                    subheader={testimonial.subheader}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="subtitle1" component="p" align="left">
                                        {testimonial.heading}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" align="justify">
                                        {testimonial.description.substr(0, 250)}
                                    </Typography>
                                </CardContent>
                            </Stack>
                        </Card>
                    </SwiperSlide>
                ))}
            </Swiper>
        </>
    );
}
