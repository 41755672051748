import React from "react";
import { Box, Typography, Button } from "@mui/material";

const PrivacyPolicy = () => {
    return (
        <Typography id="terms-description" variant="subtitle2" fontWeight={200} component="div">

            <p><strong>Last updated:</strong> November 21, 2024</p>
            <p>
                This Privacy Policy describes Our policies and procedures on the collection, use, and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.
            </p>
            <p>
                We use Your Personal Data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.
            </p>

            <div class="section">
                <Typography variant='h4' component='h2'>Interpretation and Definitions</Typography>
                <h3>Interpretation</h3>
                <p>
                    The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or plural.
                </p>
                <h3>Definitions</h3>
                <p><strong>For the purposes of this Privacy Policy:</strong></p>
                <ul>
                    <li><strong>Account:</strong> A unique account created for You to access our Service or parts of our Service.</li>
                    <li><strong>Affiliate:</strong> An entity that controls, is controlled by, or is under common control with a party.</li>
                    <li><strong>Company:</strong> Refers to Aaryan Leisure & Holidays Pvt. Ltd., located at Flat No. 1B, First Floor, Edcon Swastika Building, 7/1C, Hazra Rd, Bakul Bagan, Kalighat, Kolkata, West Bengal 700026.</li>
                    <li><strong>Cookies:</strong> Small files placed on Your Device by a website, containing details of Your browsing history.</li>
                    <li><strong>Country:</strong> Refers to West Bengal, India.</li>
                    <li><strong>Device:</strong> Any device that can access the Service, such as a computer, cellphone, or tablet.</li>
                    <li><strong>Personal Data:</strong> Information that relates to an identified or identifiable individual.</li>
                    <li><strong>Service:</strong> Refers to the Website.</li>
                    <li><strong>Service Provider:</strong> A natural or legal person who processes data on behalf of the Company.</li>
                    <li><strong>Usage Data:</strong> Data collected automatically, either generated by the use of the Service or from the Service infrastructure itself.</li>
                    <li><strong>Website:</strong> Refers to Aaryan Leisure & Holidays Pvt. Ltd., accessible at <a href="http://www.aaryanholidays.com" target="_blank">www.aaryanholidays.com</a>.</li>
                    <li><strong>You:</strong> The individual accessing or using the Service, or the company/legal entity on behalf of which such individual is accessing the Service.</li>
                </ul>
            </div>

            <div class="section">
                <Typography variant='h4' component='h2'>Collecting and Using Your Personal Data</Typography>
                <h3>Types of Data Collected</h3>
                <p><strong>Personal Data:</strong></p>
                <p>
                    While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You, including:
                </p>
                <ul>
                    <li>Email address</li>
                    <li>First name and last name</li>
                    <li>Phone number</li>
                </ul>
                <p><strong>Usage Data:</strong></p>
                <p>
                    Usage Data is collected automatically when using the Service and may include details such as IP address, browser type, time and date of visit, and more.
                </p>
            </div>

            <div class="section">
                <Typography variant='h4' component='h2'>Tracking Technologies and Cookies</Typography>
                <p>
                    We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. The technologies We use include:
                </p>
                <ul>
                    <li><strong>Cookies or Browser Cookies:</strong> Small files placed on Your Device to enhance Your browsing experience.</li>
                    <li><strong>Web Beacons:</strong> Small electronic files used for analytics and statistics.</li>
                </ul>
            </div>

            <div class="section">
                <Typography variant='h4' component='h2'>Use of Your Personal Data</Typography>
                <p>
                    The Company may use Personal Data for purposes such as providing and maintaining the Service, contacting You, managing requests, business transfers, and other purposes.
                </p>
            </div>

            <div class="section">
                <Typography variant='h4' component='h2'>Retention of Your Personal Data</Typography>
                <p>
                    The Company will retain Your Personal Data only for as long as necessary for the purposes outlined in this Privacy Policy.
                </p>
            </div>

            <div class="section">
                <Typography variant='h4' component='h2'>Disclosure of Your Personal Data</Typography>
                <p>
                    Your Personal Data may be disclosed in circumstances such as business transactions, law enforcement requests, or legal requirements.
                </p>
            </div>

            <div class="section">
                <Typography variant='h4' component='h2'>Security of Your Personal Data</Typography>
                <p>
                    While We strive to use commercially acceptable means to protect Your data, we cannot guarantee its absolute security.
                </p>
            </div>

            <div class="section">
                <Typography variant='h4' component='h2'>Children's Privacy</Typography>
                <p>
                    Our Service does not address anyone under the age of 13, and we do not knowingly collect data from minors.
                </p>
            </div>

            <div class="section">
                <Typography variant='h4' component='h2'>Changes to this Privacy Policy</Typography>
                <p>
                    We may update this Privacy Policy from time to time. Changes are effective when posted on this page.
                </p>
            </div>

            <div class="section">
                <Typography variant='h4' component='h2'>Contact Us</Typography>
                <p>If you have any questions about this Privacy Policy, you can contact us:</p>
                <ul>
                    <li>By visiting: <a href="https://www.aaryanholidays.in/contact" target="_blank">Contact Page</a></li>
                    <li>By phone number: 7044076004</li>
                </ul>
            </div>
        </Typography>
    );
};

export default PrivacyPolicy;
