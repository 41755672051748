import React, { useRef, useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import Box from '@mui/material/Box';

import CssBaseline from '@mui/material/CssBaseline';
import { alpha, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { ThemeProvider } from '@mui/material/styles';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import Link from '@mui/material/Link';
import { Autoplay, Pagination, Navigation, EffectFade } from 'swiper/modules';
import PDPSwiper from './assets/scss/swiper.scss';
import { GlobalTheme } from '../../theme';

const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + '...';
  }
  return text;
};

const DynamicMantleTourDetails = ({ banner_type, banner_src, banner_alt, banner_title, banner_description, data, price, days }) => {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    // Trigger animation once the component mounts
    const timeout = setTimeout(() => {
      setAnimate(true);
    }, 0); // Delay can be adjusted as needed
    return () => clearTimeout(timeout); // Cleanup
  }, []);

  return (
    <React.Fragment>
      <ThemeProvider theme={GlobalTheme}>
        <CssBaseline />
        <Card id="headerContainer"
          sx={{
            borderRadius: 0,
            position: 'relative',
            zIndex: 1,
            height: { md: 'auto' },
          }}>
          <Swiper
            direction={'horizontal'}
            autoHeight={true}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 3500,
              disableOnInteraction: false,
            }}
            navigation={false}
            modules={[Autoplay, Pagination, Navigation, EffectFade]}
            className={PDPSwiper.pdpSwiper}
          >
            {data.map((data) => (
              <SwiperSlide key={data}
                virtualIndex={data}
                className='travelDetailsCarouselHeight'
              >
                {data.type === 'video' ? (
                  <CardMedia
                    id={`destinationThumbsVid${data}`}
                    component="video"
                    autoPlay
                    loop
                    muted
                    playsInline>
                    <source src={`https://aaryanholidays.in/express/uploads/${data.src}`} type="video/mp4" />
                    <Typography variant='subtitle2' component='p'> Your browser does not support the video tag.</Typography>
                  </CardMedia>
                ) : (
                  <CardMedia
                    id={`destinationThumbsImg${data}`}
                    component="img"
                    image={`https://aaryanholidays.in/express/uploads/${data.src}`}
                    alt={banner_alt}
                    sx={{ height: '100%' }}
                    loading='lazy'
                  />
                )
                }
              </SwiperSlide>
            ))}
          </Swiper>

          <Box
            sx={{
              position: { md: 'absolute' },
              height: '100%',
              width: { md: '50%' },
              left: 0,
              bottom: 0,
              top: 0,
              backgroundColor: {
                xs: GlobalTheme.palette.common.black,
                md: alpha(GlobalTheme.palette.common.black, .65)
              },
              zIndex: 2,
            }}>
            <CardContent id="mantleText"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                height: '100%',
                flexDirection: 'column',
                flexWrap: 'wrap',
                alignContent: 'center',
                pl: {
                  xs: 3,
                  md: 6
                },
                pr: {
                  xs: 3,
                  md: 8
                },
                pt: {
                  xs: 3,
                  md: 10
                }
              }}>
              <Stack direction={{ sm: 'row', md: 'column' }} >
                <Box sx={{ width: { sm: '50%', md: '100%' } }}>
                  <Typography id="mantleHeading" gutterBottom variant='h1' color='white'
                    className={animate ? 'animate__animated animate__fadeInUp animate__delay-1s' : ''}
                    sx={{
                      fontSize: '2rem'
                    }}>{banner_title}</Typography>
                  <Typography id="mantleSubHeading" component='p' variant="subtitle1" color='white' gutterBottom
                    className={animate ? 'animate__animated animate__fadeIn animate__delay-2s' : ''}
                    sx={{
                      mb: 2.5
                    }}>
                    {truncateText(banner_description, 200)}
                  </Typography>
                </Box>
                <Box sx={{ width: { sm: '50%', md: '100%' }, pl: { sm: 5, md: 0 } }}  className={animate ? 'animate__animated animate__fadeIn animate__delay-4s' : ''} >
                  <Typography component='p' variant='h2' className={animate ? 'animate__animated animate__slideInDown animate__delay-4s' : ''} sx={{
                    fontWeight: 500, mb: .75,
                    textTransform: 'uppercase',
                    color: GlobalTheme.palette.warning.main,
                    fontSize: '1.5rem'

                  }}> {days}
                  </Typography>
                  <Typography component='p' variant='subtitle1' className={animate ? 'animate__animated animate__slideInDown animate__delay-4s' : ''} sx={{
                    display: 'flex',
                    alignItems: 'center',
                    fontWeight: 500,
                    color: GlobalTheme.palette.common.white
                  }}> <CurrencyRupeeIcon sx={{
                    fontSize: GlobalTheme.typography.h4.fontSize
                  }} /> {price}
                    <Typography component='span'
                      sx={{
                        fontSize: 18,
                        pl: 1
                      }}
                    >
                      only
                    </Typography>
                  </Typography>
                  <Link className={animate ? 'animate__animated animate__slideInDown animate__delay-4s' : ''} href="https://www.google.com/search?q=google+currency+converter&rlz=1C1ONGR_enIN990IN990&oq=google+currec&gs_lcrp=EgZjaHJvbWUqDAgBEAAYChixAxiABDIGCAAQRRg5MgwIARAAGAoYsQMYgAQyCQgCEAAYChiABDIJCAMQABgKGIAEMgkIBBAAGAoYgAQyCQgFEAAYChiABDIJCAYQABgKGIAEMgkIBxAAGAoYgAQyCQgIEAAYChiABDIJCAkQABgKGIAE0gEINjg2OWowajeoAgCwAgA&sourceid=chrome&ie=UTF-8" underline="hover" target='_blank'
                    sx={{
                      mt: 1
                    }}>
                    Currency converter
                  </Link>
                </Box>
              </Stack>
            </CardContent>
          </Box>

          <Box
            sx={{
              background: 'linear-gradient(0deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.1) 100%)',
            }}
            width='100%'
            height='100%'
            position='absolute'>
          </Box>
        </Card>
      </ThemeProvider>
    </React.Fragment >
  );
};

export default DynamicMantleTourDetails;
