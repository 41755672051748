

import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import Skeleton from '@mui/material/Skeleton';

import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import DynamicMantle from './../../components/mantles/DynamicMantle';
import Avatar from '@mui/material/Avatar';
import { red } from '@mui/material/colors';

import { GlobalTheme } from '../../theme';
import { Container, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TablePagination from '@mui/material/TablePagination';

import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import Button from '@mui/material/Button';

import { alpha } from '@mui/system';

import Grid from '@mui/material/Grid';

const BASE_MEDIA_URL = 'https://aaryanholidays.in/express/uploads/';

const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...';
    }
    return text;
};

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


export default function SearchResultPage() {
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const keywords = query.get('keywords');
    const videoRefs = useRef([]);
    const navigate = useNavigate();
    const handleMouseEnter = (index) => {
        const videoElement = videoRefs.current[index];
        if (videoElement && videoElement.paused) {
            videoElement.play().catch(error => console.error('Error playing video:', error));
        }
    };

    const handleMouseLeave = (index) => {
        const videoElement = videoRefs.current[index];
        if (videoElement && !videoElement.paused) {
            videoElement.pause();
            videoElement.currentTime = 0;
        }
    };
    const handleCardClick = (id) => {
        navigate(`/tour-details/4/${id}`)
    }

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (keywords) {
            const fetchResults = async () => {
                try {
                    const response = await fetch(`https://aaryanholidays.in/frontend/search-destinations?keywords=${encodeURIComponent(keywords)}`);
                    console.log(`https://aaryanholidays.in/frontend/search-destinations?keywords=${encodeURIComponent(keywords)}`);
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    const data = await response.json();
                    setResults(data);
                } catch (error) {
                    console.error('Error fetching search results:', error);
                } finally {
                    setLoading(false);
                }
            };

            fetchResults();
        }
    }, [keywords]);

    return (
        <React.Fragment>
            <ThemeProvider theme={GlobalTheme}>
                <CssBaseline />
                {/* <HomeMantle home={1} /> */}
                {/* <DynamicMantle
                    banner_type={tourTypeData.banner_type}
                    banner_src={`${BASE_MEDIA_URL}${tourTypeData.banner_src}`}
                    banner_alt={tourTypeData.banner_alt}
                    banner_title={tourTypeData.banner_title}
                    banner_description={tourTypeData.banner_description}
                /> */}
                <Box>
                    <CardMedia
                        component="img"
                        height="500"
                        image="https://as1.ftcdn.net/v2/jpg/02/54/76/30/1000_F_254763087_qAqKHN1286EPaCTqfG8lWjIJ6CT2SAM8.jpg"
                        alt=""
                    />
                </Box>
                <Container fixed sx={{ py: 5 }}>
                    {/* <Grid textAlign='center'>
                        <Typography id="destinationHeading" component='h2' variant='h2' sx={{ mb: 2 }}>
                            Search Result  For "{keywords}"
                        </Typography>

                    </Grid> */}
                    {loading ? (
                        <>
                            <Skeleton />
                            <Skeleton animation="wave" />
                            <Skeleton animation={false} />
                        </>
                    ) : results.length === 0 ? (
                        <Typography>No results found</Typography>
                    ) : (
                        <Grid container spacing={4} id='tourListing' sx={{ mt: 2 }}>
                            <Grid item xs={12} md={4}>
                                <Box sx={{
                                    backgroundColor: GlobalTheme.palette.common.white
                                }}>
                                    <Card sx={{ maxWidth: 345 }}>
                                        <CardMedia
                                            sx={{ height: 140 }}
                                            image="https://images.pexels.com/photos/27822916/pexels-photo-27822916/free-photo-of-a-photo-of-leaves-on-the-ground.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                            title="green iguana"
                                        />

                                        <Avatar alt="Remy Sharp" src="https://images.pexels.com/photos/1040880/pexels-photo-1040880.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" />

                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="div">
                                                Sanjay singh
                                            </Typography>
                                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                sanjay.singh@gmail.com
                                            </Typography>
                                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                91 9084 77980
                                            </Typography>
                                        </CardContent>
                                        <CardActions>
                                            <Button size="small">Edit</Button>
                                        </CardActions>
                                    </Card>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Box sx={{ width: '100%' }}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                            <Tab label="Upcoming Tours" {...a11yProps(0)} />
                                            <Tab label="Past Tours" {...a11yProps(1)} />
                                        </Tabs>
                                    </Box>
                                    <CustomTabPanel value={value} index={0}>
                                        Ah! no upcoming tours found.
                                    </CustomTabPanel>
                                    <CustomTabPanel value={value} index={1}>
                                        {results.map((tour, index) => (
                                            <Grid
                                                item
                                                id={`tourListing${tour.id}`}
                                                key={tour.id}
                                                xs={6}
                                                md={3}
                                                sx={{ display: 'inline-flex' }}
                                            >
                                                <Card
                                                    sx={{
                                                        position: 'relative',
                                                        borderRadius: 2,
                                                        height: 348,
                                                        overflow: 'hidden'
                                                    }}>
                                                    <CardActionArea
                                                        id={'destinationListing' + tour.id}
                                                        onClick={() => handleCardClick(tour.id)}
                                                        onMouseEnter={() => handleMouseEnter(index)}
                                                        onMouseLeave={() => handleMouseLeave(index)}
                                                        sx={{ height: 'inherit' }}
                                                    >

                                                        {tour.type === 'video' ? (
                                                            <CardMedia
                                                                id={`destinationThumbsVid${index}`}
                                                                component="video"
                                                                src={`${BASE_MEDIA_URL}${tour.src}`}
                                                                loop
                                                                muted
                                                                playsInline
                                                                ref={(el) => (videoRefs.current[index] = el)}
                                                            >
                                                                <source src={`${BASE_MEDIA_URL}${tour.src}`} type="video/mp4" />
                                                                Your browser does not support the video tag.
                                                            </CardMedia>
                                                        ) : (
                                                            <CardMedia
                                                                id={`destinationThumbsImg${index}`}
                                                                component="img"
                                                                src={`${BASE_MEDIA_URL}${tour.src}`}
                                                                alt={tour.alt}
                                                            />
                                                        )}

                                                        <CardContent
                                                            sx={{
                                                                position: 'absolute',
                                                                bottom: 0,
                                                                left: 0,
                                                                textAlign: 'left',
                                                                height: '100%',
                                                                width: '100%',
                                                                pt: 35,
                                                                transition: 'all .4s ease-in',
                                                                "&:hover": {
                                                                    pt: 23,
                                                                    backgroundColor: alpha(GlobalTheme.palette.common.tertiary, 0.5)
                                                                }
                                                            }}>
                                                            <Typography variant='h3' component='h3' sx={{ color: GlobalTheme.palette.common.white }} gutterBottom>
                                                                <span dangerouslySetInnerHTML={{ __html: tour.thumbnail_heading }} />
                                                            </Typography>
                                                            <Typography variant="body2" sx={{ color: GlobalTheme.palette.common.white }}>
                                                                {truncateText(tour.thumbnail_desc, 80)}
                                                            </Typography>
                                                        </CardContent>
                                                    </CardActionArea>
                                                </Card>
                                            </Grid>
                                        ))}
                                    </CustomTabPanel>

                                </Box>

                            </Grid>

                        </Grid>
                    )}


                </Container>
            </ThemeProvider>
        </React.Fragment>
    )
}



