import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { ThemeProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import { GlobalTheme } from '../../theme';

import CurrencyRupeeOutlinedIcon from '@mui/icons-material/CurrencyRupeeOutlined';
import FlightOutlinedIcon from '@mui/icons-material/FlightOutlined';
import BedroomBabyOutlinedIcon from '@mui/icons-material/BedroomBabyOutlined';
import RestaurantMenuOutlinedIcon from '@mui/icons-material/RestaurantMenuOutlined';
import SpaOutlinedIcon from '@mui/icons-material/SpaOutlined';
import WifiOutlinedIcon from '@mui/icons-material/WifiOutlined';
import HotelDetailStyle from './assets/scss/hotelDetails.module.scss';
import Hotels from './../../components/hotels/hotels';
import DynamicMantleTourDetails from './../../components/mantles/DynamicMantleHotel';

const HotelDetails = () => {
    const { id } = useParams();
    const [hotelDetails, setHotelDetails] = useState(null);
    const [hotelImages, setHotelImages] = useState([]);
   
   
    const [loading, setLoading] = useState(true);
    const theme = useTheme();

    useEffect(() => {
        const fetchHotelDetails = async () => {
            try {
                const [hotelsResponse, imagesResponse] = await Promise.all([
                    fetch(`https://aaryanholidays.in/frontend/data/aaryan_hotels`),
                    fetch(`https://aaryanholidays.in/frontend/data/aaryan_hotels_images`)
                ]);

                const hotelsData = await hotelsResponse.json();
                const imagesData = await imagesResponse.json();
             
    const filtered = hotelsData.filter(hotel => hotel.id === parseInt(id));
   
    setHotelDetails(filtered[0]);
                setHotelImages(imagesData.filter(img => img.hotels_id === parseInt(id)));
               // console.log(hotelImages);
            } catch (error) {
                console.error('Error fetching hotels data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchHotelDetails();
    }, [id]);

    if (loading) {
        return <Typography>Loading...</Typography>;
    }

    if (!hotelDetails) {
        return <div>Hotel not found...</div>;
    }

    return (
        <ThemeProvider theme={GlobalTheme}>
            <CssBaseline />
            <DynamicMantleTourDetails
                    banner_type=""
                    banner_src=""
                    banner_alt=""
                    banner_title={hotelDetails.name}
                    banner_description={hotelDetails.address}
                    price={hotelDetails.discount}
                    days=""
                    data={hotelImages}
                />
            <Container maxWidth='xxl' sx={{ [theme.breakpoints.up('sm')]: { px: 0 } }}>
                <Grid container spacing={2}>
                    <Grid className={HotelDetailStyle.photoArrayMantle} component='section' sx={{width: '100%'}}>
                   
                        {/* {hotelImages.slice(0, 5).map((image, index) => (
                            <Grid>
                                <img
                                    id={`mantle${index}`}
                                    src={image.src}
                                    alt={image.alt}
                                    loading="lazy"
                                    className={HotelDetailStyle.imgFluid}
                                />
                            </Grid>
                        ))} */}
                    </Grid>
                </Grid>
            </Container>

            <Container sx={{ my: 9 }}>
                <Stack direction='row'>
                    <Grid item xs={12} sm={6} md={8} sx={{ pr: 3 }}>
                        <Typography variant="h2" component='h1' color='primary' gutterBottom>{hotelDetails.name}</Typography>
                        <Typography variant="subtitle2" component='p' gutterBottom sx={{ color: GlobalTheme.palette.common.bodyTxt }}>
                            {hotelDetails.address}<br />
                            {hotelDetails.country}, {hotelDetails.state}, {hotelDetails.city} - {hotelDetails.pincode}
                        </Typography>
                        <Typography variant="subtitle2" component='p' sx={{ color: GlobalTheme.palette.common.bodyTxt, mt: 2 }}>{hotelDetails.brief}</Typography>
                        <Typography variant="h2" component='h2' sx={{ color: GlobalTheme.palette.common.tertiary, mt: 5 }} gutterBottom> what's offered</Typography>
                        <Grid sx={{ borderRadius: 2, border: '1px solid', borderColor: GlobalTheme.palette.common.grayDark, px: 3, py: 2 }}>
                            <List sx={{ display: 'flex', justifyContent: 'space-around' }}>
                                <ListItem>
                                    <Grid sx={{ flexWrap: 'wrap', justifyContent: 'center', textAlign: 'center' }}>
                                        <FlightOutlinedIcon />
                                        <Typography variant='subtitle2' sx={{ color: GlobalTheme.palette.common.bodyTxt }}>Airport <br />transfer</Typography>
                                    </Grid>
                                </ListItem>
                                <ListItem>
                                    <Grid sx={{ flexWrap: 'wrap', justifyContent: 'center', textAlign: 'center' }}>
                                        <RestaurantMenuOutlinedIcon />
                                        <Typography variant='subtitle2' sx={{ color: GlobalTheme.palette.common.bodyTxt }}>Complementary <br />Breakfast</Typography>
                                    </Grid>
                                </ListItem>
                                <ListItem>
                                    <Grid sx={{ flexWrap: 'wrap', justifyContent: 'center', textAlign: 'center' }}>
                                        <BedroomBabyOutlinedIcon />
                                        <Typography variant='subtitle2' sx={{ color: GlobalTheme.palette.common.bodyTxt }}>Children <br />play area</Typography>
                                    </Grid>
                                </ListItem>
                                <ListItem>
                                    <Grid sx={{ flexWrap: 'wrap', justifyContent: 'center', textAlign: 'center' }}>
                                        <SpaOutlinedIcon />
                                        <Typography variant='subtitle2' sx={{ color: GlobalTheme.palette.common.bodyTxt }}>Relaxing <br />Spa</Typography>
                                    </Grid>
                                </ListItem>
                                <ListItem>
                                    <Grid sx={{ flexWrap: 'wrap', justifyContent: 'center', textAlign: 'center' }}>
                                        <WifiOutlinedIcon />
                                        <Typography variant='subtitle2' sx={{ color: GlobalTheme.palette.common.bodyTxt }}>Free<br /> WiFI</Typography>
                                    </Grid>
                                </ListItem>
                            </List>
                        </Grid>

                        <Typography variant="h2" component='h2' sx={{ my: 3, color: GlobalTheme.palette.common.tertiary }}>available Rooms</Typography>
                        <Typography component='p' sx={{ mb: 3 }}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                        </Typography>
                        <Grid sx={{
                            backgroundColor: GlobalTheme.palette.common.grayLight,
                            border: '1px solid',
                            borderColor: GlobalTheme.palette.common.grayDark,
                            p: 3,
                            borderRadius: 2
                        }}>
                            <Grid className={HotelDetailStyle.photoArrayGallery} component='section'>
                                {hotelImages.slice(0, 3).map((image, index) => (
                                    <Grid>
                                        <img
                                            id={`gallery${index}`}
                                            src={image.src}
                                            alt={image.alt}
                                            loading="lazy"
                                            className={`${HotelDetailStyle.imgFluid} ${HotelDetailStyle.borderRadius8}`}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                            <Typography variant='h3' component='h3' gutterBottom>
                                Blossom
                            </Typography>
                            <Typography variant='subtitle2' component='div' sx={{ color: GlobalTheme.palette.common.bodyTxt }}>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} sx={{ px: 2 }}>
                        <Card sx={{ minWidth: 350, borderRadius: '10px', py: 4 }} elevation={8}>
                            <CardContent sx={{ px: 3, py: 0 }}>
                                <Stack direction='row' alignItems='center'>
                                    <CurrencyRupeeOutlinedIcon sx={{ fontWeight: 600, fontSize: '2.063rem' }} />
                                    <Typography gutterBottom component='p' sx={{ fontWeight: 600, fontSize: '2.063rem', mb: 0 }}>
                                        {hotelDetails.discount}/
                                    </Typography>
                                    <CurrencyRupeeOutlinedIcon sx={{ fontSize: GlobalTheme.typography.subtitle2.fontSize, textDecoration: 'line-through', color: GlobalTheme.palette.error.main }} />
                                    <Typography component="p" sx={{ fontSize: GlobalTheme.typography.subtitle2.fontSize, textDecoration: 'line-through', color: GlobalTheme.palette.error.main }}>
                                        {hotelDetails.price}
                                    </Typography>
                                </Stack>
                                <Typography component='p' variant='subtitle2' sx={{ fontWeight: 400, color: GlobalTheme.palette.common.bodyTxt }}>Per Person on twin sharing</Typography>
                            </CardContent>
                            <CardActions sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                                <Button size="large" variant='contained' sx={{ borderRadius: 10, color: GlobalTheme.palette.common.white }}>Share interest</Button>
                            </CardActions>
                        </Card>

                        <Grid sx={{ mt: 6, mb: 3 }}>
                            <Typography component='h2' variant='h2' sx={{ fontWeight: 400, color: GlobalTheme.palette.common.tertiary }}>Explore hotel location</Typography>
                            <iframe
                                title='maps'
                                src={hotelDetails.map}
                                width="100%"
                                height="349"
                                style={{ border: 0 }}
                                allowFullScreen=""
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                            ></iframe>
                        </Grid>
                        <Typography variant='h3' component='h3' gutterBottom>
                            Popular Landmark
                        </Typography>
                        <Typography variant='subtitle2' component='div' sx={{ color: GlobalTheme.palette.common.bodyTxt }}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.
                        </Typography>
                    </Grid>
                </Stack>
            </Container >

            <Container maxWidth='xxl' sx={{ backgroundColor: GlobalTheme.palette.warning.light, [theme.breakpoints.up('sm')]: { px: 0 } }}>
                <Container sx={{ py: 5 }}>
                    <Typography component='h2' variant='h2' sx={{ textAlign: 'center', mb: 2 }}>more  hotels</Typography>
                    <Typography component='p' sx={{ textAlign: 'center', mb: 3, px: 20 }}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    </Typography>
                    <Hotels />
                </Container>
            </Container>
        </ThemeProvider >
    );
};

export default HotelDetails;
