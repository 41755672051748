import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

import Navbar from './components/navbar/navbar';
import Home from './pages/home/landing';
import Footer from './components/footer/footer';
import Preloader from './components/preloader/preloader.jsx';
import 'animate.css';
import TourListing from './pages/tourListing/tourListing';
import TourDetails from './pages/tourDetails/tourDetails';
import About from './pages/about/about';
import HotelsDetails from './pages/hotels/hotelsDetails';
import Blog from './pages/blog/index';
import Contact from './pages/contact/contact';
import Login from './pages/login/login';
import Signup from './pages/login/signup';
import { ThemeProvider, alpha } from '@mui/material/styles';
import { GlobalTheme, ResponsiveGlobalTheme } from './theme';
import SearchResultPage from './components/SearchResultPage';
import TourListingContinent from './pages/tourListing/tourListing-continent';
import Profile from './pages/profile/profile';
import Box from '@mui/material/Box';
import { ReactComponent as WhatsApp } from './Digital_Glyph_Green.svg';
import ScrollToTop from './scrollToTop.jsx';

const App = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const isLoginPage = location.pathname === "/login";

  useEffect(() => {
    setLoading(true);
    // This function will be called on initial load and when the route changes
    const trackPageView = (url) => {
      window.gtag('config', 'G-NGPN3YWNZJ', {
        page_path: url,
      });
    };

    const timeout = setTimeout(() => {
      setLoading(false); // Hide the preloader after content is loaded
    }, 500);
    return () => clearTimeout(timeout);

    trackPageView(location.pathname + location.search);
  }, [location]);

  return (
    <>
      <ThemeProvider theme={ResponsiveGlobalTheme}>
        {loading && <Preloader loading={loading} />}

        <Box

          sx={{
            width: 70, py: 1, zIndex: 3,
            position: 'fixed', bottom: 25, right: 15, backgroundColor: '#25D366', borderRadius: 5, px: 1.75, pt: 1.62, boxShadow: GlobalTheme.shadows[10],
          }}>
          <a aria-label="Chat on WhatsApp" href="https://wa.me/+7044076004?text=Hi%2C%20I%20am%20planning%20a%20tour%20and%20would%20like%20to%20connect" target='_blank' rel="noreferrer">
            <WhatsApp alt="Chat on WhatsApp" />
          </a>
        </Box>
        {!['/login', '/signup'].includes(window.location.pathname) && <Navbar />}

        {!loading && (
          <main aria-labelledby='mantleText'>
            <ScrollToTop />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/tour-type/:banner_for/:refer_other_id?" element={<TourListing />} />
              <Route path="/continent/:banner_for/:refer_other_id?" element={<TourListingContinent />} />
              <Route path="/tour-details/:banner_for/:refer_other_id?" element={<TourDetails />} />
              <Route path="/searchresultpage" element={<SearchResultPage />} />
              <Route path="/about" element={<About />} />
              <Route path='/hotels/:id' element={<HotelsDetails />} />
              <Route path='/blog' element={<Blog />} />
              <Route path='/contact' element={<Contact />} />
              <Route path='/login' element={<Login />} />
              <Route path='/signup' element={<Signup />} />
              <Route path='/profile' element={<Profile />} />
            </Routes>

          </main>
        )}

        {!['/login', '/signup'].includes(window.location.pathname) && <Footer />}
      </ThemeProvider>
    </>
  );
}

export default App;
