import * as React from 'react';
import LogoStyle from './assets/scss/logo.module.scss';
import { Link } from 'react-router-dom';
import LogoImg from './assets/images/logo.svg';

const Logo = ({ fillColor = "#0ABAB5" }) => (
    <Link to={"/"}>
        <img src={LogoImg} alt='Logo Aaryan Leisure & Holidays Pvt Ltd' className={`${LogoStyle.logo} ${LogoStyle.pNone}`} itemprop="logo" />
    </Link>
);

export default Logo;