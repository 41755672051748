import * as React from 'react';
import { GlobalTheme } from './../../theme';
import { ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Logo from '../../components/logo/logo';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import GoogleLoginButton from './GoogleLoginButton';
import GoogleLogoutButton from './GoogleLogout';
import Stack from '@mui/material/Stack';

export default function SignInSide() {
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const email = data.get('email');

        const password = data.get('password');

        try {
            const response = await fetch('https://aaryanholidays.in/frontend/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
            });

            const result = await response.json();

            if (result.success) {
                // Store user data in localStorage
                //alert("jj");
                localStorage.setItem('user', JSON.stringify(result.user));

                // Redirect to index page
                navigate('/');
            } else {
                alert(result.message); // Show error message
            }
        } catch (error) {
            console.error('Error during login:', error);
            alert('An error occurred. Please try again.');
        }
    };

    return (
        <ThemeProvider theme={GlobalTheme}>
            <CssBaseline />
            <Grid container component="main" sx={{ height: '100vh' }}>
                <Grid
                    item
                    xs={false}
                    md={7}
                    sx={{
                        backgroundImage:
                            'url("https://as1.ftcdn.net/v2/jpg/04/95/50/00/1000_F_495500001_yIit7wI8SYykdaBXZVw0QRDv4CgtgUJV.jpg")',
                        backgroundColor: (t) =>
                            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: 'left'
                    }}
                />
                <Grid item xs={12} md={5} component={Paper} elevation={6} square display='flex'>
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            margin: '0 auto',
                            py: 3
                        }}
                    >
                        <Logo />
                        <Typography component="h1" variant="h5" sx={{ mt: 3 }}>
                            Sign In
                        </Typography>
                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1, px: { xs: 3, sm: 6, lg: 15 } }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        variant='standard'
                                        required
                                        fullWidth
                                        id="email"
                                        label="Email Address"
                                        name="email"
                                        autoComplete="email"
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        variant='standard'
                                        required
                                        fullWidth
                                        name="password"
                                        label="Password"
                                        type="password"
                                        id="password"
                                        autoComplete="current-password"
                                    />
                                </Grid>
                            </Grid>
                            <Box sx={{ textAlign: 'center' }}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    size="large"
                                    variant="contained"

                                    sx={{
                                        my: 3,
                                        mb: 2,
                                        py: 2,
                                        boxShadow: 'none',
                                        borderRadius: 10,
                                        color: GlobalTheme.palette.common.white
                                    }}
                                >
                                    Sign In
                                </Button>
                                <Typography component='p'>OR</Typography>
                                <GoogleLoginButton />
                            </Box>
                            <Grid container sx={{ textAlign: 'center', justifyContent: 'center' }}>
                                <Stack>
                                    <Link href="/signup" variant="body2" color={GlobalTheme.palette.common.black}>
                                        {"Don't have an account? Sign Up"}
                                    </Link>
                                    <Link href="/" variant="body2" underline="hover" sx={{ mt: 1 }} color={GlobalTheme.palette.common.bodyTxt}>
                                        {"Back to home"}
                                    </Link>
                                </Stack>
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}
