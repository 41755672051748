//import React, { useRef, useState } from 'react';
import React, { useEffect, useState } from 'react';
import { Virtual, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import Stack from '@mui/material/Stack';

import Hotels from '../../components/hotels/hotels';
import ShoppingMall from '../hotels/shoppingmall';
import KidsArena from '../hotels/kidsarena';
function HotelsShoppingKidsTab(props) {
    const { children, value, index, ...other } = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`HotelsShoppingKids-tabpanel-${index}`}
            aria-labelledby={`HotelsShoppingKids-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </Box>
    );
}

HotelsShoppingKidsTab.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `HotelsShoppingKids-tab-${index}`,
        'aria-controls': `HotelsShoppingKids-tabpanel-${index}`,
    };
}

export default function BasicTabs() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const { refer_other_id } = useParams();
    const [swiperRef, setSwiperRef] = useState(null);
    const [hotelsData, setHotelsData] = useState([]);
    const [shoppingMallData, setShoppingMallData] = useState([]);
    const [kidsArenaData, setKidsArenaData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`https://aaryanholidays.in/frontend/special-feature-package-wise/${refer_other_id}`);
                const data = await response.json();

                if (Array.isArray(data)) {
                    // Filter and map the data for each type
                    const hotelData = data.filter(item => item.type === 'Hotel').map(item => ({
                        ...item,
                        image: `https://aaryanholidays.in/express/uploads/${item.thumbnail}`,
                    }));
                    const shoppingMallData = data.filter(item => item.type === 'Shopping Mall').map(item => ({
                        ...item,
                        image: `https://aaryanholidays.in/express/uploads/${item.thumbnail}`,
                    }));
                    const kidsArenaData = data.filter(item => item.type === 'Kids Arena').map(item => ({
                        ...item,
                        image: `https://aaryanholidays.in/express/uploads/${item.thumbnail}`,
                    }));

                    // Set the data for each category
                    setHotelsData(hotelData);
                    setShoppingMallData(shoppingMallData);
                    setKidsArenaData(kidsArenaData);
                } else {
                    console.error('Unexpected data format:', data);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [refer_other_id]);
    const [slides, setSlides] = useState(
        Array.from({ length: 5 }).map((_, index) => `Slide ${index + 1}`)
    );

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="view hotels, experience shopping and kids play zone">
                   {/* Conditionally render tabs based on data availability */}
                   {hotelsData.length > 0 && <Tab label="Hotels" {...a11yProps(0)} />}
                    {shoppingMallData.length > 0 && <Tab label="Shopping" {...a11yProps(1)} />}
                    {kidsArenaData.length > 0 && <Tab label="Kids area" {...a11yProps(2)} />}
                </Tabs>
            </Box>
{/* Conditionally render tab panels based on data availability */}
{hotelsData.length > 0 && (
                <HotelsShoppingKidsTab value={value} index={0}>
                    <Hotels data={hotelsData} />
                </HotelsShoppingKidsTab>
            )}
            {shoppingMallData.length > 0 && (
                <HotelsShoppingKidsTab value={value} index={1}>
                    <ShoppingMall data={shoppingMallData} />
                </HotelsShoppingKidsTab>
            )}
            {kidsArenaData.length > 0 && (
                <HotelsShoppingKidsTab value={value} index={2}>
                    <KidsArena data={kidsArenaData} />
                </HotelsShoppingKidsTab>
            )}
        </Box>
    );
}
