import React from 'react';
import Box from '@mui/material/Box';
import LogoPreloader from './loader.gif';

import './preloader.scss';

const Preloader = ({ loading }) => {
    return (
        <Box className={`preloader ${loading ? 'active' : 'fade-out'}`}>
            <img src={LogoPreloader} alt='' />
        </Box>
    );
};

export default Preloader;
