import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { GlobalTheme } from './../../theme';
import { Container, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TablePagination from '@mui/material/TablePagination';
import { alpha } from '@mui/system';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import Grid from '@mui/material/Grid';

import DynamicMantle from './../../components/mantles/DynamicMantle';

const BASE_MEDIA_URL = 'https://aaryanholidays.in/express/uploads/';
const DEFAULT_IMAGE = 'https://via.placeholder.com/500x300?text=No+Image+Available';
const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...';
    }
    return text;
};

export default function TourListingContinent() {
    const { refer_other_id } = useParams();
    const navigate = useNavigate();
    const [tourListingData, setTourListingData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [tourTypeData, setTourTypeData] = useState({
        heading: '',
        desc_txt: '',
        continent_banner: '',
        continent_heading: '',
        continent_description: ''
    });
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(8);
    const videoRefs = useRef([]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const paginatedData = tourListingData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    const handleMouseEnter = (index) => {
        const videoElement = videoRefs.current[index];
        if (videoElement && videoElement.paused) {
            videoElement.play().catch(error => console.error('Error playing video:', error));
        }
    };

    const handleMouseLeave = (index) => {
        const videoElement = videoRefs.current[index];
        if (videoElement && !videoElement.paused) {
            videoElement.pause();
            videoElement.currentTime = 0;
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [tourResponse, tourTypeResponse] = await Promise.all([
                    fetch(`https://aaryanholidays.in/backend/continents/tour-types/${refer_other_id}`),
                    fetch(`https://aaryanholidays.in/backend/continents/${refer_other_id}`)
                ]);
                const tourData = await tourResponse.json();
                const tourTypeData = await tourTypeResponse.json();
                setPage(0);
                setTourListingData(tourData);
                setTourTypeData(tourTypeData);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching data: ', error);
            }
        }
        fetchData();
    }, [refer_other_id]);

    const handleCardClick = (id) => {
        navigate(`/tour-type/3/${id}`)
    }

    return (
        <React.Fragment>
            <ThemeProvider theme={GlobalTheme}>
                <CssBaseline />
                {tourTypeData.continent_banner && (
                    <DynamicMantle
                        banner_type="img"
                        banner_src={`${BASE_MEDIA_URL}${tourTypeData.continent_banner}`}
                        banner_alt={tourTypeData.continent}
                        banner_title={tourTypeData.continent_heading}
                        banner_description={tourTypeData.continent_description}
                        isLoading={isLoading}
                    />
                )}

                <Container fixed sx={{ py: 5 }}>
                    <Grid textAlign='center'>
                        <Typography id="destinationHeading" component='h2' variant='h2' sx={{ mb: 2 }}>
                            {tourTypeData.heading}
                        </Typography>
                        <Typography component='p'>
                            {tourTypeData.desc_txt}
                        </Typography>
                    </Grid>

                    <Grid container spacing={4} id='tourListing' sx={{ mt: 2 }}>
                        {paginatedData.map((tour, index) => (
                            <Grid
                                item
                                id={`tourListing${tour.id}`}
                                key={tour.id}
                                xs={6}
                                sm={4}
                                md={4}
                                lg={3}
                                sx={{ display: 'inline-flex' }}
                            >
                                <Card
                                    sx={{
                                        position: 'relative',
                                        borderRadius: 2,
                                        height: 400, // Set fixed height to ensure rectangular shape
                                        overflow: 'hidden'
                                    }}>
                                    <CardActionArea
                                        id={'destinationListing' + tour.id}
                                        onClick={() => handleCardClick(tour.id)}
                                        onMouseEnter={() => handleMouseEnter(index)}
                                        onMouseLeave={() => handleMouseLeave(index)}
                                        sx={{ height: 'inherit' }}
                                    >
                                        {tour.banner_type === 'video' ? (
                                            <CardMedia
                                                id={`destinationThumbsVid${index}`}
                                                component="video"
                                                src={tour.banner_src ? `${BASE_MEDIA_URL}${tour.banner_src}` : DEFAULT_IMAGE}
                                                loop
                                                muted
                                                playsInline
                                                ref={(el) => (videoRefs.current[index] = el)}
                                                sx={{ height: '100%', objectFit: 'cover' }} // Maintain aspect ratio
                                            >
                                                <source src={`${BASE_MEDIA_URL}${tour.src}`} type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </CardMedia>
                                        ) : (
                                            <CardMedia
                                                id={`destinationThumbsImg${index}`}
                                                component="img"
                                                src={tour.banner_src ? `${BASE_MEDIA_URL}${tour.banner_src}` : DEFAULT_IMAGE}
                                                alt={tour.alt}
                                                sx={{ height: '100%', objectFit: 'cover' }} // Maintain aspect ratio
                                                 loading='lazy'
                                            />
                                        )}

                                        <CardContent
                                            component='span'
                                            sx={{
                                                position: 'absolute',
                                                bottom: 0,
                                                left: 0,
                                                textAlign: 'left',
                                                height: '100%',
                                                width: '100%',
                                                pt: 35,
                                                transition: 'all .4s ease-in',
                                                "&:hover": {
                                                    pt: 23,
                                                    backgroundColor: alpha(GlobalTheme.palette.common.tertiary, 0.5)
                                                }
                                            }}>
                                            <Typography variant='h3' component='h3' sx={{ color: GlobalTheme.palette.common.white }} gutterBottom>
                                                <span dangerouslySetInnerHTML={{ __html: tour.tour_type }} />
                                            </Typography>
                                            <Typography variant="body2" sx={{ color: GlobalTheme.palette.common.white }}>
                                                {truncateText(tour.desc_txt, 80)}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        ))}

                    </Grid>
                    <Grid sx={{ mt: 3, display: 'block' }}>
                        <TablePagination
                            component="div"
                            count={tourListingData.length}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            rowsPerPageOptions={[8, 16, 24]}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Grid>
                </Container>
            </ThemeProvider>
        </React.Fragment>
    )
}
