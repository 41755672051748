import React, { useState, useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import { GlobalTheme } from './../../theme';
import { Typography } from '@mui/material';
import { Pagination } from 'swiper/modules';
import axios from 'axios';

import AM1 from './assets/images/am1.png';
import AM2 from './assets/images/am2.png';
import AM3 from './assets/images/am3.png';
import AM4 from './assets/images/am4.png';
import AM5 from './assets/images/am5.png';
import AM6 from './assets/images/am6.png';

export default function Home() {

    const [competencies, setCompetencies] = useState([]);

    useEffect(() => {
        const fetchCompetencies = async () => {
            try {
                const response = await axios.get('https://aaryanholidays.in/frontend/accrediation');
                const sortedCompetencies = response.data.sort((a, b) => a.image_order - b.image_order);
                setCompetencies(sortedCompetencies);
            } catch (error) {
                console.error('Error fetching competencies:', error);
            }
        };

        fetchCompetencies();
    }, []);
    const theme = useTheme();

    return (
        <React.Fragment>
            <ThemeProvider theme={GlobalTheme}>
                <CssBaseline />
                <Container fixed>
                    <Typography id="membersHeading" variant='h2' component='h2' sx={{ mb: 2, textAlign: 'center' }}>Accreditation &amp; membership</Typography>
                    <Swiper

                        breakpoints={{
                            0: {
                                slidesPerView: 3,
                                spaceBetween: 20,
                            },
                            768: {
                                slidesPerView: 4,
                                spaceBetween: 40,
                            },
                            1024: {
                                slidesPerView: 5,
                                spaceBetween: 50,
                            },
                        }}
                        spaceBetween={30}
                        pagination={{
                            clickable: true,
                        }}
                        modules={[Pagination]}
                        keyboard={{
                            enabled: true,
                        }}
                        style={{ paddingBottom: '40px' }}
                    >
                       
                        {competencies.map((competency) => (
                <SwiperSlide key={competency.id}>
                    <img src={`https://aaryanholidays.in/express/uploads/${competency.image}`} alt={competency.alt} />
                </SwiperSlide>
            ))}
                    </Swiper>
                </Container>
            </ThemeProvider>
        </React.Fragment>
    )
}