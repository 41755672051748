import { useGoogleLogin } from '@react-oauth/google';
import Button from '@mui/material/Button';
import axios from 'axios';
import { GlobalTheme } from './../../theme';
import { ReactComponent as GoogleLogo } from './gIcon.svg';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';


const GoogleLoginButton = () => {
  const navigate = useNavigate();
  const [snackbarOpen, setSnackbarOpen] = useState(false); // Snackbar state
    const [snackbarMessage, setSnackbarMessage] = useState(''); // Snackbar message

  const fetchGoogleUserInfo = async (accessToken) => {
    try {
      const response = await axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${accessToken}`);
      console.log("Google User Info: ", response.data);
      
      // Extract required fields from Google response
      const { given_name, family_name, email, picture } = response.data;
  
      console.log("Given Name: ", given_name);
      console.log("Family Name: ", family_name);
      console.log("Email: ", email);
      console.log("Profile Picture: ", picture);
  
      // Optionally, display user information
     // alert(`Welcome ${given_name} ${family_name}. Your email is ${email}`);
  
      // Prepare data for signup
      const userData = {
        firstName: given_name,
        lastName: family_name,
        email: email,
        phone: "", // Assume phone is empty or you can request it from the user
        password: "defaultPassword123" // Assume default password or request it from the user
      };
  
      // Call the signup API with Google user information
      await submitSignupData(userData);
    } catch (error) {
      console.error("Error fetching user info: ", error);
    }
  };
  const submitSignupData = async (userData) => {
   
    try {
      const response = await axios.post('https://aaryanholidays.in/frontend/gsignup', userData);
      
      // Check if signup was successful
      if (response.data.success) {
        console.log('User registered successfully. Please verify your email.');
  
        // Store the user data in localStorage
        localStorage.setItem('user', JSON.stringify({
          id: response.data.user.id,
          firstName: userData.firstName,
          lastName: userData.lastName,
          email: userData.email,
          phone: userData.phone
        }));
        
        setSnackbarMessage('Login successful!');
        setSnackbarOpen(true); // Open the snackbar on success
        navigate('/');
      } else {
         // Store the user data in localStorage
         localStorage.setItem('user', JSON.stringify({
          id: response.data.user.id,
          firstName: userData.firstName,
          lastName: userData.lastName,
          email: userData.email,
          phone: userData.phone
        }));
        
        setSnackbarMessage('Login successful!');
        setSnackbarOpen(true); // Open the snackbar on success
        navigate('/');
      }
    } catch (error) {
      console.error('Error submitting signup data: ', error);
      alert('Error submitting signup data.');
      
    }
  };
  const login = useGoogleLogin({
    onSuccess: tokenResponse => {
      const accesstoken = tokenResponse.access_token;

      const result = axios.post('https://aaryanholidays.in/backend/google', { id_token: accesstoken });
//alert (accesstoken);
fetchGoogleUserInfo(accesstoken); // Fetch user info from Google
//       console.log("Decoded Token: ", decodedToken);

//       // Extract user information
//       const email = decodedToken.email;
//       const name = decodedToken.name;
//       const picture = decodedToken.picture;me
//       alert(`Welcome, ${name}. Your email is ${email}.`);
      // Handle the result from the backend
      console.log(result);
    },
  });

  return (
    <>
      <Button onClick={() => login()}
        fullWidth
        size="large"
        variant="outlined"
        sx={{
          
          my: 2,
          py: 2,
          boxShadow: 'none',
          borderRadius: 10,
          borderColor: GlobalTheme.palette.common.grayDark,
          color: GlobalTheme.palette.common.bodyTxt
        }}
      >
        <GoogleLogo style={{width: 30, marginRight: 10}}/> Sign in with Google </Button>
    </>
  );
};

export default GoogleLoginButton;


