import * as React from 'react';
import { useState } from 'react';
import axios from 'axios';
import { GlobalTheme } from './../../theme';
import { ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar'; // Import Snackbar
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Logo from '../../components/logo/logo';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

function Copyright(props) {
    return (
        <Typography sx={{ mt: 5, color: GlobalTheme.palette.common.bodyTxt }}>
            &copy; Aaryan Leisure & Holidays Pvt Ltd. 2017-2023. All rights reserved.
        </Typography>
    );
}

export default function SignInSide() {
    const [msg, setMsg] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [validationErrors, setValidationErrors] = useState({});
    const [snackbarOpen, setSnackbarOpen] = useState(false); // Snackbar state
    const [snackbarMessage, setSnackbarMessage] = useState(''); // Snackbar message
    const navigate = useNavigate();

    const validateForm = (formData) => {
        const errors = {};
        let isValids = true;
        // First Name Validation
        if (!formData.firstName.trim()) {
            errors.firstName = 'First Name is required';
            isValids = false;
        } else {
            errors.firstName = '';
        }
        // Last Name Validation
        if (!formData.lastName.trim()) {
            errors.lastName = 'Last Name is required';
            isValids = false;
        } else {
            errors.lastName = '';
        }
        // Email Validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!formData.email.trim()) {
            errors.email = 'Email is required';
            isValids = false;
        } else if (!emailRegex.test(formData.email)) {
            errors.email = 'Invalid email address';
            isValids = false;
        } else {
            errors.email = '';
        }
        // Phone Validation (10 digits)
        const phoneRegex = /^[0-9]{10}$/;
        if (!formData.phone.trim()) {
            errors.phone = 'Mobile number is required';
            isValids = false;
        } else if (!phoneRegex.test(formData.phone)) {
            errors.phone = 'Invalid mobile number (must be 10 digits)';
            isValids = false;
        } else {
            errors.phone = '';
        }
        // Password Validation
        if (!formData.password.trim()) {
            errors.password = 'Password is required';
            isValids = false;
        } else if (formData.password.length < 6) {
            errors.password = 'Password must be at least 6 characters';
            isValids = false;
        } else {
            errors.password = '';
        }
        setValidationErrors(errors);
        return isValids;
    };

    const handleGSubmit = async (event) => {
        event.preventDefault();

        const data = new FormData(event.currentTarget);
        const formData = {
            firstName: data.get('firstName'),
            lastName: data.get('lastName'),
            email: data.get('email'),
            phone: data.get('phone'),
            password: data.get('password'),
        };

        const errors = validateForm(formData);
        if (!errors) {
            return;
        }
        setLoading(true);

        try {
            const response = await axios.post('https://aaryanholidays.in/frontend/signup', formData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            console.log('Signup successful:', response.data);
            setMsg('Signup successful:');
            setSnackbarMessage('Signup successful!');
            setSnackbarOpen(true); // Open the snackbar on success

            setTimeout(() => {
                navigate('/login');
            }, 3000);

        } catch (error) {
            console.log(formData);
            console.error('Signup error:', error);
            setMsg('Signup Error:');
            setSnackbarMessage('Signup Error: ' + (error.response?.data?.message || 'An error occurred'));
            setSnackbarOpen(true); // Open the snackbar on error
            if (error.response) {
                setError(`Error: ${error.response.data.message || 'An error occurred'}`);
            } else {
                setError('Network error. Please try again.');
            }
        } finally {
            setLoading(false);
        }
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false); // Close the snackbar
    };

    return (
        <ThemeProvider theme={GlobalTheme}>
            <CssBaseline />
            <Grid container component="main" sx={{ height: '100vh' }}>
                <Grid
                    item
                    xs={false}

                    md={7}
                    sx={{
                        backgroundImage:
                            'url("https://as1.ftcdn.net/v2/jpg/04/95/50/00/1000_F_495500001_yIit7wI8SYykdaBXZVw0QRDv4CgtgUJV.jpg")',
                        backgroundColor: (t) =>
                            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: 'left',
                    }}
                />
                <Grid item xs={12} md={5} component={Paper} elevation={6} square display='flex'>
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            py: 3
                        }}
                    >
                        <Logo />
                        <Typography component="h1" variant="h5" sx={{ mt: 3 }}>
                            Sign up
                        </Typography>
                        <Box component="form" noValidate onSubmit={handleGSubmit} sx={{ mt: 1, px: { xs: 3, sm: 6, lg: 15 } }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        autoComplete="given-name"
                                        name="firstName"
                                        required
                                        fullWidth
                                        id="firstName"
                                        label="First Name"
                                        variant='standard'
                                        placeholder='e.g: John'
                                        autoFocus
                                        error={!!validationErrors.firstName}
                                        helperText={validationErrors.firstName}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="lastName"
                                        label="Last Name"
                                        name="lastName"
                                        variant='standard'
                                        placeholder='e.g: Smith'
                                        autoComplete="family-name"
                                        error={!!validationErrors.lastName}
                                        helperText={validationErrors.lastName}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="email"
                                        label="Email Address"
                                        name="email"
                                        variant='standard'
                                        autoComplete="email"
                                        placeholder='e.g: abc@xyz.com'
                                        error={!!validationErrors.email}
                                        helperText={validationErrors.email}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="phone"
                                        label="Mobile"
                                        variant='standard'
                                        name="phone"
                                        autoComplete="phone"
                                        placeholder='e.g: +91 0000 000 000'
                                        error={!!validationErrors.phone}
                                        helperText={validationErrors.phone}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        name="password"
                                        label="Password"
                                        type="password"
                                        id="password"

                                        variant='standard'
                                        autoComplete="new-password"
                                        error={!!validationErrors.password}
                                        helperText={validationErrors.password}
                                    />
                                </Grid>
                            </Grid>

                            <Button
                                type="submit"
                                fullWidth
                                size="large"
                                variant="contained"
                                sx={{
                                    my: 3,
                                    mb: 2,
                                    py: 2,
                                    borderRadius: 2,
                                    color: GlobalTheme.palette.common.white
                                }}
                                disabled={loading}
                            >
                                {loading ? 'Signing Up...' : 'Sign Up'}
                            </Button>
                            {error && <Typography color="error">{error}</Typography>}
                            <Grid container sx={{ textAlign: 'center', justifyContent: 'center' }}>
                                <Link href="/login" variant="body2">
                                    Back to Login
                                </Link>
                            </Grid>
                            {/* <Copyright /> */}
                        </Box>
                    </Box>
                </Grid>
            </Grid>

            {/* Snackbar for displaying success or error messages */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={4000}
                onClose={handleCloseSnackbar}
                message={snackbarMessage}
            />
        </ThemeProvider>
    );
}
