import React, { useState } from 'react';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { GlobalTheme } from '../../theme';
import Box from '@mui/material/Box';

import Button from '@mui/material/Button';
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import TravelRequestDialog from './../dialog/travel_request';
import PropTypes from 'prop-types';

const TravelRequest = ({ size = 'large', window, ...props }) => {
    const [openTravelRequest, setOpenTravelRequest] = useState(false);

    const handleOpenTravelRequest = () => {
        setOpenTravelRequest(true);
    };

    const handleCloseTravelRequest = () => {
        setOpenTravelRequest(false);
    };

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 800,
        target: window ? window() : undefined,
    });

    return (
        <>
            <Button
                variant="contained"
                disableFocusRipple
                disableElevation
                endIcon={<EastOutlinedIcon />}
                size={size}
                {...props}
                sx={{
                    backgroundColor: trigger ? GlobalTheme.palette.common.black : GlobalTheme.palette.common.tertiary,
                    color: GlobalTheme.palette.common.white,
                    borderRadius: 0,
                    textTransform: 'uppercase',
                    fontSize: GlobalTheme.typography.subtitle1.fontSize,
                    transition: 'all 0.3s ease-in-out',
                    pt: 2.25,
                    pb: 2.125,
                    px: 3,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '8px',

                    "& .MuiButton-endIcon": {
                        opacity: 0,
                        transform: 'translateX(-2px)', // Initially hidden and shrunk
                        transition: 'transform 0.3s ease, opacity 0.3s ease', // Smooth transition for both transform and opacity
                    },
                    "&:hover .MuiButton-endIcon, &:focus .MuiButton-endIcon": {
                        opacity: 1,
                        transform: 'translateX(0)', // Slide in and grow to full size on hover
                    },
                    "& .buttonText": {
                        transition: 'transform 0.3s ease', // Smooth transition for text
                    },

                    "&:hover .buttonText, &:focus .buttonText": {
                        transform: 'translateX(-2px)', // Text moves slightly left on hover
                    },
                }}
                onClick={handleOpenTravelRequest}
            >
                <Box component='span' className="buttonText" position='relative'
                    sx={{
                        display: 'inline-block',
                        transform: 'translateX(16px)'
                    }}>
                    Travel Request
                </Box>
            </Button >

            <TravelRequestDialog open={openTravelRequest} onClose={handleCloseTravelRequest} />
        </>
    );
}

TravelRequest.propTypes = {
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    children: PropTypes.node.isRequired,
};

export default TravelRequest;